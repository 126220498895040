import { VariableManager } from '@vev/variables';
import { useEffect } from 'react';
import { useGlobalStateRef } from './use-global-state';
import { useGlobalStore } from './use-store';

export function useAppVariables(rootNodeRef: React.RefObject<HTMLDivElement>, debug = false) {
  const [stateRef] = useGlobalStateRef();
  const [variables, disabled, dispatch] = useGlobalStore(
    (s, dispatch) => [s.variables, s.editor?.disabled, dispatch],
    [],
  );

  useEffect(() => {
    const rootEl = rootNodeRef.current;

    if (rootEl && variables.length) {
      window.vevVariableDebug = debug;
      const variableManager = new VariableManager(rootEl);
      variableManager.setInitialVars(variables);
      let overrides = {};

      const cb = (e: CustomEvent<{ key: string; value: string }>) => {
        const { key: name, value } = e.detail;
        overrides = { ...overrides, [name]: value };
        dispatch('overrideVariables', overrides);
      };

      rootEl.addEventListener<any>('vev.variable.change', cb);

      return () => {
        rootEl.removeEventListener<any>('vev.variable.change', cb);
        variableManager.destroy();
        dispatch('overrideVariables', {});
      };
    }
  }, [variables, disabled, rootNodeRef, debug, stateRef, dispatch]);
}
