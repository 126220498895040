export function round(value: number, precision: number): number {
  const factor = Math.pow(10, precision);
  return Math.round(value * factor) / factor;
}

/**
 * Ensures <code>value</code> is not smaller than <code>min</code> or greater than <code>max</code>
 * @param value
 * @param min Smallest possible value
 * @param max Biggest possible value
 * @returns {number}
 */
export function clamp(value: number, min: number, max: number): number {
  return Math.max(min, Math.min(value, max));
}
