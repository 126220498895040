import { createContext, default as React, useContext, useMemo } from 'react';
import { IContent } from 'vev';
import { applyVariables, isString } from '../../utils';
import { createKeyMap, getModelOverrides, isObjectEmpty } from './utils';
import { VariantContext, VariantOverrideProvider } from './variant';

type ModelsContextModel = { [key: string]: IContent };
type VirtualModelContextModel = {
  masterKey: string;
  instanceKey: string;
  instanceKeyChain: string[];
  overrides: { [modelKey: string]: any };
  variant?: string;
};

const ModelsContext = createContext<ModelsContextModel | null>(null);
const ModelContext = createContext<IContent>({ key: '' });
const VirtualModelContext = createContext<VirtualModelContextModel | null>(null);
export const VariablesContext = createContext<Record<string, any> | null>({});

export const ModelProvider = ({
  value,
  children,
}: {
  value: IContent;
  children: React.ReactNode;
}) => {
  const masterKey = value.master;
  if (isString(masterKey)) {
    return (
      <VariantOverrideProvider variantKey={value.variant}>
        <ModelContext.Provider value={value}>
          <VirtualModelProvider
            overrides={value.childContent || {}}
            instanceKey={value.key}
            masterKey={masterKey}
          >
            {children}
          </VirtualModelProvider>
        </ModelContext.Provider>
      </VariantOverrideProvider>
    );
  }
  return <ModelContext.Provider value={value}>{children}</ModelContext.Provider>;
};

export function ModelsProvider({
  models,
  children,
}: {
  models: IContent[];
  children: React.ReactNode;
}) {
  const modelMap = useMemo(() => createKeyMap(models), [models]);
  return <ModelsContext.Provider value={modelMap}>{children}</ModelsContext.Provider>;
}

export function VirtualModelProvider({
  overrides,
  masterKey,
  instanceKey,
  children,
}: {
  overrides?: { [modelKey: string]: any };
  masterKey: string;
  instanceKey: string;
  children: React.ReactNode;
}) {
  const virtualParent = useContext(VirtualModelContext);

  const virtualModel: VirtualModelContextModel = useMemo(() => {
    const instanceKeyChain = virtualParent?.instanceKeyChain.slice() || [];
    instanceKeyChain.unshift(instanceKey);

    const parentOverrides = virtualParent?.overrides || {};
    const newOverrides = { ...overrides };

    for (const parentKey in parentOverrides) {
      if (newOverrides[parentKey]) {
        // Parent override is the top instance, so that should override the new overrides
        newOverrides[parentKey] = { ...newOverrides[parentKey], ...parentOverrides[parentKey] };
      } else newOverrides[parentKey] = parentOverrides[parentKey];
    }

    return {
      masterKey,
      instanceKey,
      instanceKeyChain,
      overrides: newOverrides,
    };
  }, [masterKey, instanceKey, virtualParent, overrides]);

  return (
    <VirtualModelContext.Provider value={virtualModel}>{children}</VirtualModelContext.Provider>
  );
}

export function useContextModel(key?: string, overrideVariantKey?: string): IContent | undefined {
  const models = useContext(ModelsContext);
  const contextState = useContext(ModelContext);
  const virtualModel = useContext(VirtualModelContext);

  const model = models?.[key || ''];
  const master = models?.[model?.master?.toString() || ''];
  const modelKey = key || contextState?.key || '';
  const instanceKeyChain = virtualModel?.instanceKeyChain;
  const variantContext = useContext(VariantContext);
  const variableMap = useContext(VariablesContext);

  const virtualKey = useMemo(() => {
    if (!instanceKeyChain?.length || !key || instanceKeyChain.includes(key)) return key;
    return `${key}-${instanceKeyChain.join('-')}`;
  }, [key, instanceKeyChain]);

  const variant = models?.[overrideVariantKey ? overrideVariantKey : model?.variant || ''];

  const variantModelOverrides: Partial<IContent> | undefined = useMemo(() => {
    return variant?.overrides?.[master?.key || modelKey];
  }, [master, variant, modelKey]);

  return useMemo(() => {
    if (!key) return { ...contextState } as IContent;
    const instanceKeyChain = virtualModel?.instanceKeyChain || [];

    const sortedInstanceOverrides: (Partial<IContent> | undefined)[] = [
      virtualModel?.overrides[key],
    ];

    const sortedVariantOverrides: (Partial<IContent> | undefined)[] = [
      variantModelOverrides,
      variantContext?.[master?.key || ''],
      variantContext?.[key],
    ];

    // If the model is a instance, prevent children override from nested variant
    if (master?.key) delete sortedVariantOverrides[1]?.children;

    // Loops over inherited instances and variants to get all overrides
    for (let i = 0; i < instanceKeyChain.length - 1; i++) {
      const path = `${key}-${instanceKeyChain.slice(0, i + 1).join('-')}`;

      if (virtualModel?.overrides[path]) {
        sortedInstanceOverrides.push(virtualModel?.overrides?.[path]);
      }

      if (variantContext?.[path]) {
        sortedVariantOverrides.push(variantContext?.[path]);
      }
    }

    const overrides = getModelOverrides(sortedVariantOverrides.concat(sortedInstanceOverrides));

    if (master || !isObjectEmpty(overrides)) {
      const cl = [];

      if (overrides?.type) cl.push(overrides.type);
      else if (model?.type) cl.push(model.type);

      if (overrides?.master) cl.push(overrides.master);
      else if (master) cl.push(master.key);

      if (overrides?.preset) cl.push(overrides.preset);
      else if (model?.preset) cl.push(model.preset);

      // Interaction variant override
      if (overrideVariantKey && overrideVariantKey !== 'default') {
        overrides.variant = overrideVariantKey;
      }

      const actions = overrides?.actions || model?.actions;
      const children = overrides?.children || master?.children || model?.children || [];
      const content = {
        ...master?.content,
        ...model?.content,
        ...overrides?.content,
        children: overrides?.content?.children || model?.content?.children || [],
      };

      return {
        ...master,
        ...model,
        ...overrides,
        children,
        content,
        virtualKey,
        fromModel: key,
        cl: cl.join(' '),
        actions: !actions?.length ? undefined : actions,
      } as IContent;
    } else if (virtualModel) {
      return {
        ...model,
        fromModel: key,
        key: virtualKey,
        actions: !model?.actions?.length ? undefined : model?.actions,
      } as IContent;
    }
    const [content, variablesApplied] = applyVariables(model?.content, variableMap);
    return (
      model && {
        ...model,
        content,
        variablesApplied,
        actions: !model?.actions?.length ? undefined : model?.actions,
      }
    );
  }, [
    key,
    model,
    virtualModel,
    contextState,
    master,
    variantContext,
    variantModelOverrides,
    virtualKey,
    variableMap,
    overrideVariantKey,
  ]);
}

export function useModels() {
  return useContext(ModelsContext);
}
