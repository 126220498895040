export const VARIABLE_REGEX = /^{{(.*?)}}$/;

export function isVariable(value: string): boolean {
  return !!getVariablesFromText(value).length;
}

export function getVariablesFromText(input: string): string[] {
  const regex = new RegExp(VARIABLE_REGEX, 'g');
  const matches = [];
  let match;

  while ((match = regex.exec(input)) !== null) {
    matches.push(match[1]);
  }

  return matches;
}
