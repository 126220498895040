import {
  App,
  StateProvider,
  fetchPage,
  getBaseDir,
  polyfills,
  setLoadedPage,
  vev,
} from '@vev/react';
import React from 'react';
import * as react from 'react-dom/client';
import { IAppState } from 'vev';
import { connectCLI } from './cli-connection';
import './globals';
import { initShadowDomHashLinks } from './shadow-dom-hahs-links';
import './viewer.scss';

declare global {
  interface Window {
    // Vev register widgets
    vevr: WidgetRegisterCB[] | { push: (val: WidgetRegisterCB) => void };
    vevs: { [key: string]: IAppState };
    vevLocal: boolean;
  }
}

if (!window.vevs) window.vevs = {};
const states: { [projectKey: string]: IAppState } = window.vevs;

const animationName = 'vevd';
const vevNodes: HTMLElement[] = [];

type WidgetRegisterCB = (vev: any, React: any, System: any) => void;
const HTTP_REG = /^(https?:)?\/\//;

const viewerOption = getViewerParam();
if (viewerOption && !window.vevLocal) {
  if (viewerOption === 'local.preact') runPreactViewer();
  if (viewerOption === 'local.https') runLocalViewerHttps();
  else runLocalViewer();
} else if (!(window as any).vev) {
  (window as any).vev = vev;
  checkForCLI();

  // Import polyfill
  polyfills().then(() => vev.raf(initVev));
} else {
  console.warn('Multiple vev scripts loaded');
}

function initVev() {
  vev.View.updateSize();
  // Vev widget register list
  const widgetRegister = window.vevr as WidgetRegisterCB[];
  if (widgetRegister) {
    for (let i = 0; i < widgetRegister.length; i++) {
      widgetRegister[i](vev, vev.s, React);
    }
  }
  // Replace register with fake array
  window.vevr = { push: (cb: WidgetRegisterCB) => cb(vev, vev.s, React) };

  mapNodes(document.querySelectorAll('noscript.vev-defer'), initDeferredStyles);
  mapNodes(document.querySelectorAll('script[type="text/vev"]'), initProjectContent);
  // Listen for animation start to detect vevroot elements (animationstart is triggered even if the element was added before script was loaded)
  document.addEventListener('animationstart', onNodeDetect, false);
  const detectNodes = ['.vev'];
  // Adding style animation to vevroot
  const style = document.createElement('style');
  style.innerHTML = `@keyframes ${animationName}{from{ opacity: 0.99}to{ opacity: 1}}${detectNodes.join(
    ',',
  )}{animation-duration: 0.001s;animation-name: ${animationName}}`;

  document.head.appendChild(style);
}

function onNodeDetect(e: AnimationEvent) {
  if (e.animationName === animationName) initVevRoot(e.target as HTMLElement);
}

async function initVevRoot(node: HTMLElement) {
  if (vevNodes.indexOf(node) !== -1) return;
  if (node.shadowRoot) node = node.shadowRoot.children[0] as HTMLElement;
  vevNodes.push(node);

  // Calculate the distance from the top of the first Vev element to the page top.
  if (node.getBoundingClientRect().top !== null) {
    vev.View.updateOffsetTop(node.getBoundingClientRect().top + window.scrollY);
  }

  const path = (node.dataset.path || '').replace(HTTP_REG, '').replace(/\/$/, '');

  // Attach Shadow Dom
  const shadow = node.hasAttribute('shadow') ? document.createElement('div') : undefined;

  function initShadowDom() {
    if (!shadow) return;
    shadow.setAttribute('class', `p${states[path].project}`);
    shadow.attachShadow({ mode: 'open' });
    node.insertAdjacentElement('afterend', shadow);
    shadow.shadowRoot?.appendChild(node);
  }
  // If first path part contains "." it's a domain and we try to fetch it from domain
  if (!states[path] && path.split('/')[0].split('.').length > 1) {
    const [domain, ...projectPath] = path.split('/');
    const { state, html, scripts, style } = await fetchPage('https://' + path);
    states[path] = state;
    state.host =
      '//' + domain + '/' + getBaseDir(projectPath.join('/'), state.route.pageKey, state.pages);
    state.embed = !node.dataset.router;
    initShadowDom();

    if (shadow && style) shadow.shadowRoot?.appendChild(style.cloneNode(true));

    node.innerHTML = html;
    await fetchDeps(scripts.map((src) => (HTTP_REG.test(src) ? src : 'https://' + domain + src)));
  } else if (shadow) {
    initShadowDom();
    Array.from(document.getElementsByClassName('vev-style')).forEach((node) => {
      shadow?.shadowRoot?.appendChild(node.cloneNode(true));
    });
  }

  const state = states[path];
  if (state) {
    setLoadedPage(state.route.pageKey);
    state.dir = getBaseDir(location.pathname, state.route.pageKey, state.pages);
    render(state, node);
  }
}

function fetchDeps(deps: string[]) {
  return Promise.all(deps.map((url) => vev.s.fetch(url)));
}

function initDeferredStyles(node: Element) {
  if (node.textContent) {
    const replacement = document.createElement('div');
    replacement.innerHTML = node.textContent || '';
    document.body.appendChild(replacement);
  }
  node.remove();
}

function initProjectContent(node: Element) {
  const json = node.textContent;
  if (json) {
    const state = JSON.parse(json) as IAppState;
    states[state.project + '/' + state.route.pageKey] = state;
    node.remove();
  }
}

function mapNodes(col: NodeListOf<Element>, cb: (node: Element) => void) {
  for (let i = 0; i < col.length; i++) cb(col[i]);
}

async function importAll(state: IAppState): Promise<void> {
  const imports: { [type: string]: boolean } = {};
  for (const { type } of state.models) {
    if (type !== undefined && state.pkg[type]) {
      imports[state.pkg[type]] = true;
    }
  }
  try {
    await vev.s.waitForRegister(Object.keys(imports));
  } catch (e) {
    console.error('Failed to load all widgets', e);
  }
}

export async function render(
  state: IAppState,
  container: HTMLElement = document.getElementById(state.project || '') as HTMLElement,
) {
  if (!container) return console.error('Failed to render vev, missing root node');

  await Promise.all([importAll(state), polyfills()]);

  const jsx = (
    <StateProvider state={state}>
      <App />
    </StateProvider>
  );

  if (container.innerHTML.length > 3) {
    if (react.hydrateRoot) {
      react.hydrateRoot(container, jsx);
    } else {
      // @ts-expect-error - ajo Reroute to preact hydrate
      react.hydrate(jsx, container);
    }
  } else {
    if (react.createRoot) {
      react.createRoot(container).render(jsx);
    } else {
      // @ts-expect-error - ajo Reroute to preact render
      react.render(jsx, container);
    }
  }
  // If shadow dom container
  if (container.getRootNode() instanceof DocumentFragment) initShadowDomHashLinks(container);
}

function checkForCLI() {
  setTimeout(() => location.search.includes('cli=true') && connectCLI(), 3000);
}

function getViewerParam(): string | undefined {
  const res = location.search.match(/viewer=(local|local\.preact|local\.https)(?=(&|$))/i);
  if (res) return res[1];
}

function runLocalViewer(baseURL = 'http://localhost:8080') {
  window.vevLocal = true;
  console.info('Running viewer from local server');

  const src = baseURL + '/vev.js';
  const script = document.createElement('script');
  script.src = src;
  document.body.appendChild(script);

  const style = document.createElement('link');
  style.rel = 'stylesheet';
  style.href = baseURL + '/vev.css';
  document.body.appendChild(style);
}

function runLocalViewerHttps() {
  runLocalViewer('https://localhost:8080');
}

function runPreactViewer() {
  const src = `https://cdn.vev.design/v/preact/vev.js`;
  console.info('Running preact viewer from CDN');

  const script = document.createElement('script');
  script.src = src;
  document.body.appendChild(script);
}
