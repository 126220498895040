import { VARIABLE_REGEX } from '@vev/utils';
import { IContent } from 'vev';

export function applyVariables(
  content: IContent['content'] | undefined,
  variableMap?: Record<string, any> | null,
): [IContent['content'], boolean] {
  if (!variableMap || !Object.keys(variableMap).length) return [content, false];

  return applyVariablesRecursive(content, variableMap);
}

function applyVariablesRecursive(value: any, variableMap: Record<string, any>): any {
  let appliedVariables = false;
  if (typeof value === 'string') {
    value = value.replace(VARIABLE_REGEX, (match, key) => {
      key = key.trim();
      if (key in variableMap) {
        appliedVariables = true;
        return variableMap[key];
      }
      return match;
    });
  } else if (Array.isArray(value)) {
    value = value.map((item) => {
      const [newItemValue, applied] = applyVariablesRecursive(item, variableMap);
      if (applied) appliedVariables = true;
      return newItemValue;
    });
  } else if (typeof value === 'object' && value !== null) {
    const prevValue = value;
    value = {};
    for (const key in prevValue) {
      const [newItemValue, applied] = applyVariablesRecursive(prevValue[key], variableMap);
      if (applied) appliedVariables = true;
      value[key] = newItemValue;
    }
  }
  return [value, appliedVariables];
}
