/**
 * This files sets up the global variables and system import for widgets
 * Adding all the ts-polyfills and ts libs and making them globally available
 */

// Required polyfills to work in IE
// import 'ts-polyfill/lib/es2015-core';
// import 'ts-polyfill/lib/es2015-promise';
// // Nice to have polyfills not required
// import 'ts-polyfill/lib/es2015-collection';
// import 'ts-polyfill/lib/es2016-array-include';
// import 'ts-polyfill/lib/es2017-string';
// import 'ts-polyfill/lib/es2017-object';
// import 'ts-polyfill/lib/es2018-promise';

import * as tslib from 'tslib';
import { vev, System } from '@vev/react';
import React from 'react';
import ReactDOM from 'react-dom';
import animejs from 'animejs';
import events from 'events';

if (!('remove' in Element.prototype)) {
  // @ts-ignore
  Element.prototype.remove = function () {
    // @ts-ignore
    if (this.parentNode) {
      // @ts-ignore
      this.parentNode.removeChild(this);
    }
  };
}

System.add({
  vev,
  react: { default: React, ...React },
  'react-dom': { default: ReactDOM, ...ReactDOM },
  animejs: { default: animejs },
  events: { default: events },
});
Object.assign(window, tslib);
