import { useRef } from 'react';

export function useCallbackRef(
  cb: (...args: any[]) => any,
): React.MutableRefObject<(...args: any[]) => any> {
  const ref = useRef(cb);
  ref.current = cb;

  return ref;
}
