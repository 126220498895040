import { customAlphabet } from 'nanoid/non-secure';

// CHANGING THIS VALUE WILL BREAK MAIN COMPONENTS
const KEY_LENGTH = 10;

const nanoid = customAlphabet(
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz-',
  KEY_LENGTH,
);

export function generateGuid() {
  return nanoid();
}
