import { IShapePath } from './shape';

export enum EmojiType {
  emoji = 0,
  icon = 1,
  upload = 2,
}

export type IEmoji = {
  key: string;
  char: string;
  updated: number;
};

export type IPickerPropertyType = {
  emoji: 0;
  icon: 1;
  upload: 2;
};

export type IEmojiIcon =
  | { type: EmojiType.emoji; value: string }
  | { type: EmojiType.icon; value: IShapePath }
  | { type: EmojiType.upload; value: { url: string } };
