import React, { forwardRef, LegacyRef, useCallback, useEffect, useRef, useState } from 'react';
import { useGlobalStateRef, useGlobalStore } from '../../core';
import { IAppState, IFileLink } from 'vev';
import { loadPage, pagePathByKey } from '../../utils/route';

export enum LinkMode {
  PAGE = 0,
  WIDGET = 1,
  EXTERNAL = 2,
  EMAIL = 3,
  PHONE = 4,
  FILE = 5,
}

interface ILink {
  mode: LinkMode;
}

interface PageAndWidgetProps {
  page: string;
  tweenIn?: any;
  tweenOut?: any;
  inFront?: any;
}

type IPageLink = ILink &
  PageAndWidgetProps & {
    mode: LinkMode.PAGE;
  };

type IWidgetLink = ILink &
  PageAndWidgetProps & {
    mode: LinkMode.WIDGET;
    widget: { key: string; page: string };
  };

interface IPhoneLink extends ILink {
  mode: LinkMode.PHONE;
  phone: string;
}

interface IEmailLink extends ILink {
  mode: LinkMode.EMAIL;
  email: string;
  subject?: string;
}

interface IExternalLink extends ILink {
  mode: LinkMode.EXTERNAL;
  href: string;
  target: boolean;
  targetTop?: boolean;
  nofollow: boolean;
  sponsored: boolean;
  queryParams: boolean;
}

interface LinkProps {
  to: ILink;
  className?: string;
  style?: { [attr: string]: any };
  children: React.ReactNode;
  queryParams?: boolean;
}

export function getLinkUrl(link: ILink, state: IAppState): string {
  if (!link) return '#';
  const externalLink = link as IExternalLink;

  if (link.mode === LinkMode.EXTERNAL && externalLink?.href === './') return './';

  switch (link.mode) {
    case LinkMode.PAGE: {
      const pageKey = (link as IPageLink).page;
      return pagePathByKey(pageKey, state.pages, state.dir);
    }
    case LinkMode.WIDGET:
      if ((link as IWidgetLink).widget) {
        const { page, key } = (link as IWidgetLink).widget;
        return `${pagePathByKey(page, state.pages, state.dir)}#${key}`;
      }
      return '';
    case LinkMode.EMAIL: {
      const { email, subject } = (link || {}) as IEmailLink;
      return 'mailto:' + email + (subject ? `?subject=${subject}` : '');
    }
    case LinkMode.PHONE:
      return 'tel:' + (link as IPhoneLink).phone;
    case LinkMode.FILE:
      // @ts-expect-error it's there, trust me
      return link?.file?.url;
    default:
      if (!externalLink?.href) return '#';
      if (
        externalLink &&
        !externalLink?.href?.startsWith('http') &&
        !externalLink?.href?.startsWith('/')
      ) {
        return 'http://' + externalLink.href;
      }
      return externalLink.href;
  }
}

export function getLinkTween(link: ILink): string | false {
  if ((link.mode <= 1 && (link as IPageLink).tweenIn) || (link as IPageLink).tweenOut) {
    return JSON.stringify({
      inFront: (link as IPageLink).inFront,
      tweenIn: (link as IPageLink).tweenIn,
      tweenOut: (link as IPageLink).tweenOut,
    });
  }

  return false;
}

function Link({ to, children, ...rest }: LinkProps, ref: LegacyRef<HTMLAnchorElement>) {
  const href = useGlobalStore((state) => to && getLinkUrl(to, state), [to]);
  const [state, dispatch] = useGlobalStateRef();
  const r = useRef<HTMLAnchorElement | null>(null);
  // let href: undefined | string,
  const tween: string | false = to ? getLinkTween(to) : false;
  const pageKey = (to as IPageLink)?.page;
  const [pageLoaded, setPageLoaded] = useState(false);

  const loadPageCallback = useCallback(() => {
    if (pageKey) loadPage(pageKey, state.current, dispatch);
  }, [pageKey, dispatch, state]);

  useEffect(() => {
    const el = r.current;
    if (!el) return;

    const mouseEnter = () => {
      if (pageKey && !pageLoaded) loadPageCallback();
      setPageLoaded(true);
    };
    el.addEventListener('mouseenter', mouseEnter);
    return () => el.removeEventListener('mouseenter', mouseEnter);
  }, [pageLoaded, pageKey, loadPageCallback]);

  useEffect(() => {
    if (to && r.current) {
      r.current.href = href;
    }
  });

  const rel: string[] = [];
  if (to && (to as IExternalLink).nofollow) rel.push('nofollow');
  if (to && (to as IExternalLink).sponsored) rel.push('sponsored');

  const attrs: React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    'data-forward-query-params'?: string;
  } = { href };

  if (to?.mode === LinkMode.FILE) {
    const { download, target } = (to || {}) as IFileLink;
    if (!target || target === 'download') {
      attrs.download = download || true;
    }

    if (target === 'blank' || !target || target === 'download') {
      attrs.target = '_blank';
      rel.push('nofollow');
    }
  } else if (to?.mode === LinkMode.EMAIL || (to as IExternalLink)?.target) {
    attrs.target = '_blank';
  } else if ((to as IExternalLink)?.targetTop) {
    attrs.target = '_top';
  }
  if ((to as IExternalLink)?.queryParams) {
    attrs['data-forward-query-params'] = 'true';
  }

  if (rel.length) attrs.rel = rel.join(' ');

  return (
    <a key={href} ref={r} data-tween={tween} {...rest} {...attrs}>
      {children}
    </a>
  );
}
export default React.memo(forwardRef(Link));
