import Intersection from './intersection';

export { Intersection };
export * from './type';
export * from './dom';
export * from './animation';
export * from './variables';

export function createUID() {
  return Date.now().toString(36) + '-' + Math.round(Math.random() * 10000).toString(36);
}
