import { useGlobalStore } from './use-store';

/**
 * `useDevice` watches and returns the id of the current device
 */
export function useDevice(): string {
  return useGlobalStore((state) => state.device, []);
}

/**
 * `useMainDevice` watches and returns the id of the current main device
 */
export function useMainDevice(): string {
  return useGlobalStore((state) => state.masterMode, []);
}
