import { ITransition } from './transition';
import { WidgetModel } from './widget';

export enum LinkMode {
  PAGE = 0,
  WIDGET = 1,
  EXTERNAL = 2,
  EMAIL = 3,
  PHONE = 4,
  FILE = 5,
}

export type LinkPageValue = {
  mode: LinkMode.PAGE;
  page?: string;
  target?: boolean;
} & ITransition;

export type LinkElementValue = {
  mode: LinkMode.WIDGET;
  widget?: WidgetModel;
};

export type LinkExternalValue = {
  mode: LinkMode.EXTERNAL;
  href?: string;
  nofollow?: boolean;
  sponsored?: boolean;
  target?: boolean;
};

export type LinkEmailValue = {
  mode: LinkMode.EMAIL;
  email?: string;
  subject?: string;
};

export type LinkPhoneValue = {
  mode: LinkMode.PHONE;
  phone?: string;
};

export type LinkFileValue = {
  mode: LinkMode.FILE;
  download?: string;
  title?: string;
  file?: {
    name: string;
    size: number;
    type: string;
    url: string;
  };
};

export type LinkFieldValue =
  | LinkPageValue
  | LinkElementValue
  | LinkExternalValue
  | LinkEmailValue
  | LinkPhoneValue
  | LinkFileValue;
