'use client';

import animejs from 'animejs';
import events from 'events';
import React, { useEffect, useRef } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import * as ReactDOM from 'react-dom';
import * as vev from '../../core';
import { StateProvider } from '../../core/state';
import System from '../../system';
import App from '../app';
import { fetchDeps, importAll } from './utils';

export default function Client({
  data,
  scripts,
}: {
  data: any;
  pageKey: string;
  scripts: string[];
}) {
  const ref = useRef<HTMLDivElement>(null);
  const runOnce = useRef(false);

  useEffect(() => {
    const el = ref.current;
    const app = el?.previousElementSibling;
    if (!app || !app.children?.[0]) return;
    const container = app.children[0] as HTMLElement;

    if (runOnce.current) return;
    runOnce.current = true;

    function initVev() {
      (window as any).vev = vev;

      System.add({
        vev,
        react: { default: React, ...React },
        'react-dom': { default: ReactDOM, ...ReactDOM },
        animejs: { default: animejs },
        events: { default: events },
      });

      (window as any).vevr = {
        push: (cb: any) => cb(vev, System, React),
      };
    }

    initVev();

    let mounted = true;
    (async () => {
      if (!mounted) return;

      await fetchDeps(scripts.filter((s: string) => !s.includes('vev.js')));
      await Promise.all([importAll(data)]);

      const jsx = (
        <StateProvider state={{ ...data, embed: true, pageApiUrl: 'embed.vev.page/v1' }}>
          <App />
        </StateProvider>
      );

      if (ReactDOMClient.hydrateRoot) {
        ReactDOMClient.hydrateRoot(container, jsx);
      } else {
        // @ts-expect-error - ajo Reroute to preact hydrate
        react.hydrate(jsx, container);
      }
    })();

    return () => {
      mounted = false;
    };
  }, [data]);

  return <div ref={ref} />;
}
