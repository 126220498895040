const singleFrameCbs: Set<(timestamp?: number) => void> = new Set();
const foreverFrameCbs: Set<(timestamp?: number) => void> = new Set();
let pendingFrameId: false | number = false;

function onFrame(timestamp: number) {
  for (const cb of singleFrameCbs) cb(timestamp);
  for (const cb of foreverFrameCbs) cb(timestamp);
  if (singleFrameCbs.size) singleFrameCbs.clear();
  // If any forever frames then request new frame
  if (foreverFrameCbs.size) doRaf();
  else pendingFrameId = false;
}

function doRaf() {
  pendingFrameId = requestAnimationFrame(onFrame);
}

/**
 * Request animation Frame
 * @returns cancel/unsubscribe function
 */
export function raf(cb: (timestamp?: number) => void, runForever = false): () => void {
  const list = runForever ? foreverFrameCbs : singleFrameCbs;
  list.add(cb);

  if (!pendingFrameId) doRaf();

  return () => {
    list.delete(cb);
  };
}
