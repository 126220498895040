import { useCallback, useRef } from 'react';
import { useGlobalStore } from './use-store';
import { VevSystemVariables } from '@vev/interactions';

export function useSystemVariableSubscription() {
  const stateCb = useRef<(systemVariables: VevSystemVariables) => void>(() => {});

  useGlobalStore((s) => {
    stateCb.current({
      'vev/width': s.viewport.width,
      'vev/height': s.viewport.height,
      'vev/device': s.device,
      'vev/scrollPixelsY': s.scrollTop,
      'vev/scrollPercentageY': Math.trunc(
        ((s.scrollTop + s.viewport.height) / s.viewport.scrollHeight) * 100,
      ),
    });
  });

  return useCallback((cb: (systemVariables: VevSystemVariables) => void) => {
    stateCb.current = cb;
  }, []);
}
