import { useEffect, useRef } from 'react';
import { useCallbackRef } from './use-callback-ref';

type ClickListener = (e: MouseEvent) => void;
let listeners: Set<ClickListener>;

function emit(e: MouseEvent) {
  for (const cb of listeners) cb(e);
}

function onBodyClick(cb: (e: MouseEvent) => void) {
  if (!listeners) {
    listeners = new Set();
    document.addEventListener('click', emit, { passive: true });
  }

  return () => {
    listeners.delete(cb);
  };
}

/**
 * `useOutsideClick` watches for clicks outside a given ref and calls the callback when it happens
 */
export function useOutsideClick(callback: (event: MouseEvent) => void, deps?: readonly unknown[]) {
  const ref = useRef<Element>();
  const cbRef = useCallbackRef(callback);

  useEffect(() => {
    return onBodyClick((event: MouseEvent) => {
      const el = ref.current;
      if (el && !el.contains(event.target as Node)) cbRef.current(event);
    });
  }, deps);

  return ref;
}
