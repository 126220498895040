import { IObserverOptions } from 'vev';
import { isNumber } from './type';

type IObserved = {
  el: Element;
  cb: (entry: IntersectionObserverEntry) => any;
  observer: IntersectionObserver;
};

const formatOffset = (value?: number | string): string => {
  if (isNumber(value)) return value * -1 + 'px';
  if (value) return value.charAt(0) === '-' ? value.substr(1) : '-' + value;
  return '0px';
};

const getObserveOptions = (options?: IObserverOptions): IntersectionObserverInit | undefined => {
  if (!options) return undefined;
  // Init threshold
  if (typeof options === 'number') options = { steps: options };
  const { offsetTop, offsetBottom, steps, threshold } = options;

  const observerOpts: IntersectionObserverInit = {};
  if (offsetTop || offsetBottom) {
    observerOpts.rootMargin = `${formatOffset(offsetTop)} 0px ${formatOffset(offsetBottom)} 0px`;
    observerOpts.root = null;
  }

  if (steps) {
    observerOpts.threshold = [];
    for (let i = 0; i <= steps; i++) observerOpts.threshold.push(i / steps);
  } else if (threshold) observerOpts.threshold = threshold;
};

type ObserveListener = (entry: IntersectionObserverEntry) => any;

class ElementObserver {
  observer?: IntersectionObserver;
  listeners: ObserveListener[] = [];
  prevEvent?: IntersectionObserverEntry;
  constructor(public element: Element, private options: IntersectionObserverInit | undefined) {}

  private update() {
    if (this.listeners.length) {
      if (!this.observer) {
        this.observer = new IntersectionObserver(([entry]) => {
          this.listeners.forEach((cb) => cb(entry));
          this.prevEvent = entry;
        }, this.options);
        this.observer.observe(this.element);
      }
    } else if (this.observer) {
      this.observer.disconnect();
      delete this.observer;
    }
  }

  listen(cb: (entry: IntersectionObserverEntry) => any) {
    this.listeners.push(cb);
    this.update();
    // Notify about previous event in cases where this is initialized lazy
    if (this.prevEvent) cb(this.prevEvent);

    return () => {
      const index = this.listeners.indexOf(cb);
      if (index > -1) {
        this.listeners.splice(index, 1);
        this.update();
      }
    };
  }
}

class InterSectionObserverManager {
  observers: ElementObserver[] = [];
  observed: IObserved[] = [];

  add(el: Element, cb: (entry: IntersectionObserverEntry) => any, options?: IObserverOptions) {
    const observerOptions = getObserveOptions(options);
    return this.getOrCreateObserver(el, observerOptions).listen(cb);
  }

  private getOrCreateObserver(
    el: Element,
    options: IntersectionObserverInit | undefined,
  ): ElementObserver {
    const observer = this.observers.find((o) => o.element === el);
    if (observer) return observer;

    const newObserver = new ElementObserver(el, options);
    this.observers.push(newObserver);
    return newObserver;
  }
}

export default new InterSectionObserverManager();
