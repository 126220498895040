import { TokenIterator, TokenType } from './expression-iterator';
import { isFunction } from './util';

const operators: Record<string, (a: number, b: number) => number> = {
  '+': (a: number, b: number) => a + b,
  '-': (a: number, b: number) => a - b,
  '*': (a: number, b: number) => a * b,
  '/': (a: number, b: number) => a / b,
  '^': (a: number, b: number) => Math.pow(a, b),
};

const functions: Record<string, (...args: number[]) => number> = {
  sqrt: Math.sqrt,
  sin: Math.sin,
  cos: Math.cos,
  acos: Math.acos,
  asin: Math.asin,
  tan: Math.tan,
  atan: Math.atan,
};

const operatorPrecedence = {
  '+': 1,
  '-': 1,
  '*': 2,
  '/': 2,
  '^': 3,
};

// 0 = left, 1 = right
const operatorAssociativity = {
  '+': 0,
  '-': 0,
  '*': 0,
  '/': 0,
  '^': 1,
};

function isOp(char: string): char is '+' | '-' | '*' | '/' | '^' {
  return ['+', '-', '*', '/', '^'].includes(char);
}

function hasPrecedenceOver(opA: string, opB: string) {
  if (!isOp(opA)) throw new Error();
  if (!isOp(opB)) throw new Error();
  return operatorPrecedence[opA] > operatorPrecedence[opB];
}

function hasSamePrecedence(opA: string, opB: string) {
  if (!isOp(opA)) throw new Error();
  if (!isOp(opB)) throw new Error();
  return operatorPrecedence[opA] === operatorPrecedence[opB];
}

function getAssociativity(op: string) {
  if (!isOp(op)) throw new Error();
  return operatorAssociativity[op];
}

function applyOperator(operatorStack: string[], operandStack: number[]) {
  const operator = operatorStack.pop();
  const b = operandStack.pop();
  const a = operandStack.pop();
  if (!operator || b === undefined || a === undefined) throw new Error('Invalid expression');
  operandStack.push(operators[operator](a, b));
}

function applyFunction(functionStack: string[], operandStack: number[]) {
  const functionName = functionStack.pop();
  if (!functionName) throw new Error('Invalid function');
  if (functionName === 'random') {
    operandStack.push(Math.random());
    return;
  }
  const func = functions[functionName];
  const arg = operandStack.pop();
  if (!arg) throw new Error('No function call value');
  operandStack.push(func(arg));
}

const tokenIterator = new TokenIterator();

// An evaluating version of the shunting yard algorithm: https://en.wikipedia.org/wiki/Shunting_yard_algorithm
export function evaluateExpression(expression: string, scope: Record<string, number>): number {
  tokenIterator.init(expression, scope);

  const operatorStack: string[] = [];
  const operandStack: number[] = [];

  for (const token of tokenIterator) {
    switch (token.type) {
      case TokenType.NUMBER:
        operandStack.push(token.value);
        break;
      case TokenType.FUNCTION:
        operatorStack.push(token.value);
        break;
      case TokenType.OPERATOR:
        while (
          operatorStack.length &&
          operatorStack[operatorStack.length - 1] !== '(' &&
          (hasPrecedenceOver(operatorStack[operatorStack.length - 1], token.value) ||
            (hasSamePrecedence(operatorStack[operatorStack.length - 1], token.value) &&
              getAssociativity(token.value) === 0))
        ) {
          applyOperator(operatorStack, operandStack);
        }
        operatorStack.push(token.value);
        break;
      case TokenType.PARENS:
        if (token.value === '(') {
          operatorStack.push(token.value);
        } else if (token.value === ')') {
          while (operatorStack[operatorStack.length - 1] !== '(') {
            if (isFunction(operatorStack[operatorStack.length - 1])) {
              applyFunction(operatorStack, operandStack);
            } else {
              applyOperator(operatorStack, operandStack);
            }
          }
          operatorStack.pop(); // Discard '('

          if (isFunction(operatorStack[operatorStack.length - 1])) {
            applyFunction(operatorStack, operandStack);
          }
        }
        break;
    }
  }

  while (operatorStack.length) {
    if (isFunction(operatorStack[operatorStack.length - 1])) {
      applyFunction(operatorStack, operandStack);
    } else {
      applyOperator(operatorStack, operandStack);
    }
  }

  if (operandStack.length !== 1) throw new Error('Invalid expression');
  return operandStack[0];
}
