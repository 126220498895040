/**
 * Get all inherited keys from a mocked key
 * ie. 'model-instance-instance2' => ['model-instance', 'model']
 * @param key mocked key
 * @returns a array of all models that are inherited from the given key
 */
export function getInheritedKeysFromKey(key: string): string[] {
  const keys = [] as string[];
  const nestedMainCompCount = Math.floor(key.length / 11) - 1;
  for (let i = 0; i < nestedMainCompCount; i++) {
    const inheritedKey = key.slice(0, 11 * (i + 1) + i);
    keys.push(inheritedKey);
  }
  return keys;
}
