import { ReactNode } from 'react';
import { SchemaContextModel } from './context';

export type EvalCallback = { type: '__eval__'; value: string };
export type ImageType = { url: string; key: string };
export type VideoType = { url: string; key: string };
export type ShapeType = {
  key: string;
  name: string;
  path: [number, number, string];
};

export type UploadType =
  | {
      size?: number;
      name?: string;
      url: string;
      type?: string;
    }
  | string;

type FieldComponent<T extends NativeTypes> = (
  props: SchemaFieldProps<SchemaFieldTypes[T]>,
) => ReactNode | null;

export type BaseField<Type extends NativeTypes, TsType> = {
  type: Type;
  valueType?: TsType;
  name: string;
  title?: string;
  description?: string;
  storage?: 'project' | 'workspace' | 'account';
  initialValue?:
    | TsType
    | EvalCallback
    | ((context: Omit<SchemaContextModel, 'actions'>) => TsType | Promise<TsType>);
  // onChange?: (value: T, context: SchemaContextModel) => void | Promise<void> | EvalCallback;
  onChange?: (
    value: TsType,
    context: SchemaContextModel,
  ) => void | TsType | Promise<void | TsType> | EvalCallback;
  validate?: (value: TsType, context: SchemaContextModel) => boolean | Promise<boolean>;
  hidden?: boolean | ((context: SchemaContextModel) => boolean | Promise<boolean>);
  component?: FieldComponent<Type>;
  preview?: PreviewType | ((value: { [key: string]: any }) => PreviewType);
};

type PreviewType = { title?: string; image?: string | ReactNode };

export type SelectItem<T = any> = { label: string; value: T };

export type StringField = BaseField<'string', string> & {
  options?: {
    type: 'text' | 'date' | 'email' | 'url' | 'password';
    multiline?: boolean | number;
    minLength?: number;
    maxLength?: number;
    placeholder?: string;
  };
};

export type NumberField = BaseField<'number', number> & {
  options?: {
    min?: number;
    max?: number;
    /**
     * The number visible is scaled by factor,
     * @example if you want percent 0-1 set scale = 100
     * */
    scale?: number;
    format?: string;
    display?: 'input' | 'slider';
    placeholder?: string;
  };
};

export type BooleanField = BaseField<'boolean', boolean>;

export type ImageField = BaseField<'image', ImageType>;

export type VideoField = BaseField<'video', VideoType>;

export type IconField = BaseField<'icon', ShapeType>;

export type SelectFieldItem = { label: string; value: any };

export type SelectField = BaseField<'select', any | any[]> & {
  options: {
    items:
      | SelectFieldItem[]
      | ((context: SchemaContextModel) => SelectFieldItem[] | Promise<SelectFieldItem[]>);
    multiselect?: boolean;
    display?: 'checkbox' | 'radio' | 'dropdown' | 'autocomplete';
  };
};

export type ObjectField = BaseField<'object', { [key: string]: any }> & {
  fields: VevProps[];
};

export type ArrayField = BaseField<'array', any[]> &
  (
    | {
        of: VevProps[] | 'string' | 'number';
      }
    | { of: 'object'; fields: VevProps[] }
  );

export type LinkField = BaseField<
  'link',
  {
    /**
     * Preset for what linkType the field should be.
     * 0: Page, 1: Element, 2: External link, 3: Email, 4: Phone.
     */
    mode: 0 | 2 | 3 | 4;
    href?: string;
    page?: string; // page key
    target?: boolean;
    phone?: string;
    email?: string;
  }
>;

export type LayoutField = Omit<BaseField<'layout', { [key: string]: any }>, 'name'> & {
  fields: VevProps[];
  name?: string;
  options?: {
    display: 'row' | 'column';
  };
};

export type EventField = BaseField<'event', { key: string; args?: any }[]>;
export type UploadField = BaseField<'upload', UploadType> & { accept?: string; maxSize?: number };
export type WidgetSelectField = BaseField<'widgetSelect', string>;
export type MenuField = BaseField<'menu', string>;
export type VariableField = BaseField<'variable', string> & {
  variableType?: 'color' | 'number' | 'font' | 'text';
};

export type SchemaFieldTypes = {
  string: StringField;
  array: ArrayField;
  object: ObjectField;
  number: NumberField;
  image: ImageField;
  video: VideoField;
  icon: IconField;
  boolean: BooleanField;
  select: SelectField;
  link: LinkField;
  layout: LayoutField;
  event: EventField;
  upload: UploadField;
  widgetSelect: WidgetSelectField;
  menu: MenuField;
  variable: VariableField;
};

export type NativeTypes = keyof SchemaFieldTypes;
export type VevProps = SchemaFieldTypes[NativeTypes];
export type Schema = VevProps[];

export type SchemaFieldProps<T extends VevProps> = {
  label: string;
  value: T['valueType'];
  schema: T;
  help?: string;
  onChange: (value: NonNullable<T['valueType']> | null) => void;
  context: SchemaContextModel;
};

export type SchemaFieldComponents = {
  [P in keyof Partial<SchemaFieldTypes>]: FieldComponent<P>;
};

export function isPropsObject(vevProp: VevProps): vevProp is ObjectField {
  return Object.prototype.hasOwnProperty.call(vevProp, 'fields');
}
