import React, { Fragment } from 'react';
import { ModelProvider } from '../core/context';
import { useWidget, useModel } from '../core/hooks';

interface AddonProps {
  id: string;
  children: React.ReactNode;
  hostRef: React.RefObject<HTMLDivElement>;
}

interface AddonsProps {
  addons?: string[];
  hostRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}
export function Addon({ id, children, hostRef }: AddonProps) {
  const model = useModel(id);
  const Widget = useWidget(model?.type);

  if (!model || !model.type || !Widget || model.deleted) return <Fragment>{children}</Fragment>;

  let cl = '__a ' + model.type;
  if (model.cl) cl += ' ' + model.cl;

  return (
    <ModelProvider value={model}>
      <Widget {...model.content} className={cl} id={model.key} hostRef={hostRef}>
        {children}
      </Widget>
    </ModelProvider>
  );
}

export function Addons({ addons, hostRef, children }: AddonsProps) {
  if (!addons || !addons.length) return <Fragment>{children}</Fragment>;

  return (
    <Addon id={addons[0]} hostRef={hostRef}>
      <Addons addons={addons.slice(1)} hostRef={hostRef}>
        {children}
      </Addons>
    </Addon>
  );
}
