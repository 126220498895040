import * as react from 'react-dom/client';

export const isBrowser = typeof window !== 'undefined';
export const global = isBrowser ? self : ({} as Window);
export const doc = isBrowser ? document : ({} as Document);
export const docEl = doc.documentElement;
export const root: HTMLElement = doc.body;
export const scrollEl = docEl || doc.scrollingElement || root;

// Pollufil for add and remove class (IE10 does not support multiple arguments)
export function addClass(el: HTMLElement | Element, ...cls: (string | undefined | false)[]) {
  for (const cl of cls) if (cl) el.classList.add(cl);
}

export function removeClass(el: HTMLElement, ...cls: (string | undefined | false)[]) {
  for (const cl of cls) if (cl) el.classList.remove(cl);
}

export function getById(id: string, insideEl?: Element): Element | null {
  return insideEl ? insideEl.querySelector('#' + id) : doc.getElementById(id);
}

export function hasAttrs(el: Element, attrs: string | string[]): number {
  if (typeof attrs === 'string') attrs = [attrs];
  for (let i = 0; i < attrs.length; i++) if (el.hasAttribute(attrs[i])) return i;
  return -1;
}

export function removeEl(el: Element) {
  el.parentNode && el.parentNode.removeChild(el);
}

export function unmountContent(tag: Element): void {
  const root = react.createRoot(tag);
  root.unmount();

  removeEl(tag);
}

export function findParent(tagname: string, el: HTMLElement, levels = 15): HTMLElement | null {
  let parent: HTMLElement | null = el;
  while (parent && levels--) {
    if ((parent.nodeName || parent.tagName) === tagname) {
      return parent;
    }
    parent = parent.parentNode as HTMLElement;
  }
  return null;
}

export function getViewHeight(): number {
  return doc.compatMode === 'BackCompat' && doc.body
    ? doc.body.clientHeight
    : doc.documentElement?.clientHeight;
}

export function getViewWidth(): number {
  return doc.compatMode === 'BackCompat' && doc.body
    ? doc.body.clientWidth
    : doc.documentElement?.clientWidth;
}

export function getScrollHeight(): number {
  const scrollEl = doc.documentElement || doc.scrollingElement || root;

  return scrollEl.scrollHeight;
}

export function getScrollTop(): number {
  const scrollEl = doc.documentElement || doc.scrollingElement || root;
  return global.pageYOffset || (scrollEl as any)?.scrollTop;
}
const liveEvents: { [event: string]: (e: any) => void } = {};
export function onLiveEvent<K extends keyof HTMLElementEventMap>(
  tagName: string,
  eventName: K,
  listener: (ev: HTMLElementEventMap[K], source: HTMLElement) => any,
  eventRoot: HTMLElement = root,
): () => void {
  let handler: any;
  if (eventRoot) {
    handler = liveEvents[`${tagName}:${eventName}`] = (e: HTMLElementEventMap[K]) => {
      const target = findParent(tagName.toUpperCase(), (e.target || e.srcElement) as HTMLElement);
      if (target) listener(e, target);
    };

    eventRoot.addEventListener(eventName, handler);
  }
  // Unsubscribe function
  return () => {
    if (handler) {
      eventRoot.removeEventListener(eventName, handler);
    }
  };
}

export function isDOMEvent(eventName: string) {
  const safeEventName = eventName.toLowerCase();
  const allowedEvents = ['onClick', 'onMouseEnter', 'onMouseLeave', 'onDoubleClick'].map((e) =>
    e.toLowerCase(),
  );

  return allowedEvents.includes(safeEventName);
}
