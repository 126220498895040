import { ImageModel } from 'vev';
import { useGlobalStore } from './use-store';

/**
 * `useImage` watches and returns a give image with the given key
 */
export function useImage(imageKey: string): ImageModel | undefined {
  const images = useGlobalStore((state) => state.images);
  return images[imageKey];
}
