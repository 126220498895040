import { useContext, useEffect, useRef, useState } from 'react';
import { IAppState, VevDispatch } from 'vev';
import { useContextModel } from '../context';
import { StateContext, getState } from '../state';

function hasChanged<U>(current: U, next: U, debugLabel?: string): boolean {
  if (Array.isArray(current) && Array.isArray(next)) {
    if (current.length !== next.length) return true;
    let i = current.length;
    while (i--) {
      if (current[i] !== next[i]) {
        if (debugLabel) console.log(`#${debugLabel}: change to array index ${i}`);
        return true;
      }
    }

    return false;
  }
  if (debugLabel && next !== current) {
    if (debugLabel) console.log(`#${debugLabel}: change to value`);
  }
  return next !== current;
}

/**
 * `useGlobalStore` gives access to the global state of the app + global dispatcher
 * The mapper function is used to pick which attributes to watch, and re-ender only happens if the given attributes changes
 * The mapper function also give access to the package state dispatch to store new variables in the
 * @example
 *    const [route, dispatch] = useGlobalStore((store, dispatch) => [store.route, dispatch]);
 *    <div onClick={() => dispatch('route', {pageKey: newPageKey})}>
 */
export function useGlobalStore<U>(
  mapper: (store: IAppState, dispatch: VevDispatch) => U,
  deps?: any[],
  debugLabel?: string,
): U {
  const state = useRef<U>();
  const [listen, dispatch, uid] = useContext(StateContext);
  if (!state.current) state.current = mapper(getState(uid), dispatch);
  const [, forceUpdate] = useState<number>();

  useEffect(() => {
    return listen((s) => {
      const next = mapper(s, dispatch);
      if (hasChanged(state.current, next, debugLabel)) {
        state.current = next;
        forceUpdate(Date.now());
      }
    });
  }, deps || []);

  return state.current;
}

/**
 * `useStore` can be used to share state between widgets in the same package
 * The mapper function is used to pick which attributes to watch, and re-ender only happens if the given attributes changes
 * The mapper function also give access to the package state dispatch to store new variables in the
 * @example
 *    const [count, dispatch] = useStore((store, dispatch) => [store.count, dispatch]);
 *    <div onClick={() => dispatch('count', count+1)}>
 */
export function useStore<T, U>(
  mapper: (store: T, dispatch: (action: string, payload: any) => void) => U,
  deps?: any[],
): U {
  const model = useContextModel();
  const type = model && model.type;
  return useGlobalStore((state, dispatch) => {
    const pkg = (type && state.pkg[type]) || type;
    const pkgState = ((pkg && state.pkgStores[pkg]) || {}) as T;
    return mapper(pkgState, (action, payload) => dispatch(action, payload, pkg || undefined));
  }, deps);
}
