import { RGBA } from '../types';
import { isString, isArray } from '../utils';

function getHexValue(value: number): string {
  return ('0' + (value || 0).toString(16)).slice(-2);
}

export function rgbToHex(color: RGBA | string | number[]): string {
  let hex;
  if (isString(color)) hex = color;
  else if (isArray(color)) {
    hex = getHexValue(color[0]) + getHexValue(color[1]) + getHexValue(color[2]);
  } else {
    hex = getHexValue(color.r) + getHexValue(color.g) + getHexValue(color.b);
  }

  if (hex[0] === hex[1] && hex[2] === hex[3] && hex[4] === hex[5]) {
    return `${hex[0]}${hex[2]}${hex[4]}`;
  }

  return hex;
}
