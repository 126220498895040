import { useState } from 'react';

/**
 * `useHover` a simple way to detect hover of a html element
 * @example ```
 * const [hover, bind] = useHover();
 * <div {...bind}>{hover ? 'Is hovering' : 'not hovering'}</div>
 * ```
 */
export function useHover(): [
  boolean,
  {
    onMouseEnter: (e: MouseEvent) => void;
    onMouseLeave: (e: MouseEvent) => void;
  },
] {
  const [isHovered, setHovered] = useState(false);
  const bind = {
    onMouseEnter: () => setHovered(true),
    onMouseLeave: () => setHovered(false),
  };
  return [isHovered, bind];
}
