import { initInteractionsNode } from '@vev/interactions';
import { useContext, useEffect, useRef } from 'react';
import { VariablesContext } from '../context';
import { useGlobalStateRef } from './use-global-state';
import { useGlobalStore } from './use-store';
import { useSystemVariableSubscription } from './use-system-variable-subscription';
import View from '../../manager/view';

export function useAppInteractions(rootNodeRef: React.RefObject<HTMLDivElement>, debug = false) {
  const variableMap = useContext(VariablesContext);
  const variableValueRef = useRef(variableMap);
  variableValueRef.current = variableMap;

  const systemVariableSub = useSystemVariableSubscription();
  const [state, dispatch] = useGlobalStateRef();
  const [pageKey, interactions, disabled, animations] = useGlobalStore(
    (s) => [s.route.pageKey, s.interactions, s.editor?.disabled, s.animations],
    [],
  );
  const projectKey = state.current.project;
  useEffect(() => {
    const rootNode = rootNodeRef.current;
    if (rootNode && projectKey && !disabled) {
      return initInteractionsNode({
        interactions,
        page: pageKey,
        project: projectKey,
        animations,
        rootNode,
        requestVariable(name) {
          return variableValueRef.current?.[name];
        },
        debug,
        systemVariableSub,
      });
    }
  }, [
    interactions,
    pageKey,
    projectKey,
    disabled,
    animations,
    rootNodeRef,
    debug,
    systemVariableSub,
  ]);

  useEffect(() => {
    window.addEventListener<any>('vev.navigate.internal', (e: CustomEvent) => {
      const { pageKey, widgetKey } = e.detail;
      if (pageKey) dispatch('route', { pageKey });
      if (widgetKey) View.scrollTo(widgetKey);
    });
  });
}
