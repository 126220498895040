import { useEffect } from 'react';
import { loadPage } from '../../utils/route';
import { useGlobalStateRef } from './use-global-state';

/**
 * `usePreloadPage` will preload a page for faster navigation
 * @example
 *    usePreloadPage(<pageKey>);
 */
export function usePreloadPage(pageKeys: string | string[]) {
  const [stateRef, dispatch] = useGlobalStateRef();

  useEffect(() => {
    if (!pageKeys) return;

    if (Array.isArray(pageKeys)) {
      pageKeys.forEach((pageKey) => {
        loadPage(pageKey, stateRef.current, dispatch);
      });
    } else {
      loadPage(pageKeys, stateRef.current, dispatch);
    }
  }, [dispatch, pageKeys, stateRef]);
}
