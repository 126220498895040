import { useMemo } from 'react';
import { IEditorState } from 'vev';
import { useContextModel } from '../context';
import { useGlobalStore } from './use-store';

/**
 * `useEditorState` watches and returns the editor state for the current model
 */
export function useEditorState(): IEditorState {
  const model = useContextModel();
  const [editor, rightPanelTab] = useGlobalStore(
    (state) => [state.editor, state.rightPanelTab],
    [],
  );

  let selected = false,
    rule = 'host',
    disabled = false,
    schemaOpen = false,
    activeContentFrame = false,
    activeContentChild: string | undefined = undefined;
  if (editor && model) {
    if (
      (editor.selection && editor.selection.indexOf(model.key) !== -1) ||
      editor.action === model.key ||
      editor.action === model.fromModel ||
      editor.action === model.virtualKey
    ) {
      selected = true;
      rule = editor.rule;

      if (editor.disabled && rightPanelTab === 'schema') schemaOpen = true;
    }
    disabled = editor.disabled;

    if (disabled && editor?.activeContentFrame) {
      const { parent, child } = editor.activeContentFrame;
      // Check for main component slider
      activeContentFrame = parent === model.key || parent === model.virtualKey;
      if (activeContentFrame) activeContentChild = child?.slice(0, 11);
    }
  }

  return useMemo(
    () => ({ selected, disabled, rule, schemaOpen, activeContentFrame, activeContentChild }),
    [selected, rule, disabled, schemaOpen, activeContentChild, activeContentFrame],
  );
}
