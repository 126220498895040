import { useEffect, useState } from 'react';
import Keyboard, { ShortcutModel } from './keyboard';
import { isString } from '../utils';
import { KeyboardEventCode, ModifierKey } from './keys';

export function useKey(
  key: KeyboardEventCode | KeyboardEventCode[] | undefined,
  cb: (e: KeyboardEvent) => void,
  deps?: React.DependencyList,
) {
  useEffect(() => {
    if (key) {
      Keyboard.onKey(key, cb, true);
    }

    return () => {
      if (key) {
        Keyboard.offKey(key, cb);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function useKeyUp(
  key: KeyboardEventCode | KeyboardEventCode[] | undefined,
  cb: (e: KeyboardEvent) => void,
  deps?: React.DependencyList,
) {
  useEffect(() => {
    if (key) {
      const list = isString(key) ? [key] : key;
      list.forEach((s) => Keyboard.onUp(s, cb));
      return () => list.forEach((s) => Keyboard.offUp(s, cb));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function useModifierDown(key: ModifierKey): boolean {
  const [isDown, setDown] = useState<boolean>(false);
  useEffect(() => {
    const modifierKey = Keyboard.getModifierKey(key);
    const onChange = () => setDown(Keyboard[modifierKey] as boolean);
    Keyboard.onAny(onChange);
    return () => Keyboard.offAny(onChange);
  }, [key]);
  return isDown;
}

export function useShortcut(
  shortcut: string | string[],
  cb: (e: KeyboardEvent, shortcut: ShortcutModel, shortcutId: string) => void,
  deps?: React.DependencyList,
) {
  useEffect(() => {
    const list = isString(shortcut) ? [shortcut] : shortcut;
    list.forEach((s) => Keyboard.onShortcut(s, cb));
    return () => list.forEach((s) => Keyboard.offShortcut(s, cb));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function useArrowNavigate(
  currentIndex: number,
  maxIndex: number,
  setIndex: (value: number) => void,
  onEnter?: () => void,
) {
  useKey(
    ['ArrowUp', 'ArrowDown'],
    (e) => {
      const dir = e.key === 'ArrowUp' ? -1 : 1;
      let index = currentIndex + dir;
      if (index < 0) index = maxIndex;
      if (index > maxIndex) index = 0;
      setIndex(index);
    },
    [currentIndex, maxIndex, setIndex],
  );

  useKey('Enter', () => onEnter && onEnter(), [onEnter]);
}
