import { isString } from '../utils';
import { SPACE_REG, COMMA_SPACE_REG, ComputedCSSProps, ComputedCSS } from './utils';

/**
 * Convert css object to string
 * @param css object to convert into string
 * @param pretty prettify the css string
 */
export function computedCSSToString(css: ComputedCSS, pretty?: boolean): string {
  let res = '';
  for (const selector in css) {
    const block = styleBlockToString(css[selector], selector);
    if (block.length) res += `${selector}{${block}}`;
  }

  if (pretty) {
    res = res.replace(/;/g, ';\n\t').replace(/{/g, '{\n\t').replace(/}/g, '\n}\n');
  }

  return res;
}

function styleBlockToString(styles: ComputedCSSProps, selector: string): string {
  const block: string[] = [];
  for (const attr in styles) {
    let value = styles[attr];
    if (value && !isString(value)) {
      block.push(`${attr}{${styleBlockToString(value, attr)}}`);
    } else {
      value = cleanCSSValue(value);
      if (value) block.push(`${attr}:${value}`);
    }
  }

  return block.join(/^@(media|keyframes)/.test(selector) ? ' ' : ';');
}

export function cleanCSSValue(value: string | null): string | null {
  if (!isString(value)) return value;
  return value.trim().replace(SPACE_REG, ' ').replace(COMMA_SPACE_REG, ',');
}
