import { isString, startCase } from '../utils';
import { KeyboardEventCode } from './keys';

const isMac = typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Mac OS X') !== -1;

export enum ShortcutTrack {
  skip = 'skip',
  basic = 'basic',
  advanced = 'advanced',
}

export type KeyCode =
  | KeyboardEventCode
  | `unrestricted:${KeyboardEventCode}`
  | `key:${KeyboardEventCode}`;

const REPLACE_REG = [
  { reg: /^Super/i, val: isMac ? 'keyboard.command' : 'Ctrl' },
  { reg: /Alt/i, val: isMac ? 'keyboard.option' : 'Alt' },
  { reg: /Shift/i, val: isMac ? 'keyboard.shift' : 'Shift' },

  { reg: /Delete/i, val: 'del' },
  { reg: /Backspace/i, val: 'keyboard.backspace' },

  { reg: /Enter/i, val: 'keyboard.enter' },
  { reg: /Escape/i, val: 'esc' },

  { reg: /ArrowUp/i, val: 'arrow.up' },
  { reg: /ArrowDown/i, val: 'arrow.down' },
  { reg: /ArrowLeft/i, val: 'arrow.left' },
  { reg: /ArrowRight/i, val: 'arrow.right' },
];

const RESTRICTED_TAGS: { [tag: string]: true } = {
  INPUT: true,
  TEXTAREA: true,
};

const RESTRICTED_ROLES: { [role: string]: true } = {
  textbox: true,
  formbutton: true,
};

/**
 * Determines whether or not Vev is running inside an iframe
 */
export function isWithinIframe(): boolean {
  return window.location !== window.parent.location;
}

export interface ShortcutModel {
  label: string;
  /** Description of the shortcut */
  desc: string;
  /** List of keys used for shortcut */
  keys: KeyboardEventCode[];
  /** Prevent default */
  default?: boolean;

  unrestricted?: boolean;
  ignore?: boolean;
}

type KeyboardListeners = { [event: string]: ((...args: any[]) => void)[] };

function isFocusingInput() {
  const { activeElement } = document;
  if (!activeElement) {
    return false;
  }

  return (
    activeElement.tagName === 'INPUT' ||
    activeElement.tagName === 'TEXTAREA' ||
    (activeElement as HTMLElement).contentEditable === 'true'
  );
}

class Keyboard {
  altDown = false;
  ctrlDown = false;
  shiftDown = false;
  metaDown = false;
  superDown = false;
  spaceDown = false;
  zDown = false;

  private keymap: { [key: string]: KeyboardEventCode } = {};
  private listeners: KeyboardListeners = {};
  private upListeners: KeyboardListeners = {};
  private anyKeyListeners: ((e: this) => void)[] = [];
  private isEnabled = false;
  private list: ShortcutModel[] = [];
  private shortcuts: { [shortcutId: string]: ShortcutModel } = {};

  register(shortcuts: { [shortcutId: string]: ShortcutModel }) {
    this.shortcuts = shortcuts;
    for (const id in shortcuts) {
      const shortcut = shortcuts[id];
      this.list.push(shortcut);
      for (const key of shortcut.keys) this.keymap[key] = id as KeyboardEventCode;
    }
  }

  disable() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);
    window.removeEventListener('blur', this.handleBlur);
    this.isEnabled = false;
  }

  enable(shortcuts: { [shortcutId: string]: ShortcutModel }) {
    if (shortcuts) this.register(shortcuts);
    if (!this.isEnabled) {
      document.addEventListener('keydown', this.handleKeyDown);
      document.addEventListener('keyup', this.handleKeyUp);
      window.addEventListener('blur', this.handleBlur);

      this.isEnabled = true;
    }
  }

  getEventId(e: KeyboardEvent): KeyboardEventCode {
    const keyCode = e.code || '';
    let res = e.key;

    /**
     * Use keyCode for 0-9 and letters:
     * event.key can be tricky because some modifiers like alt will transform key to a symbol
     * also handles uppercase transform with shift
     */
    if (keyCode.includes('Digit') || keyCode.includes('Key')) {
      res = keyCode.slice(keyCode.length - 1).toLowerCase();
    }

    /** Append modifiers */
    if (e.shiftKey) res = 'Shift+' + res;
    if (e.altKey) res = 'Alt+' + res;
    if ((isMac && e.metaKey) || (!isMac && e.ctrlKey)) {
      res = 'Super+' + res;
    }
    return res as KeyboardEventCode;
  }

  getModifierKey(e: KeyboardEventCode) {
    switch (e) {
      case 'Alt':
        return 'altDown';
      case 'Control':
        return 'ctrlDown';
      case 'Meta':
        return 'metaDown';
      case 'Shift':
        return 'shiftDown';
      case 'Super':
        return 'superDown';
      case 'z':
        return 'zDown';
      default:
        return 'spaceDown';
    }
  }

  getFormatted(keys: KeyboardEventCode | KeyboardEventCode[]): string[] {
    if (isString(keys)) keys = [keys];
    const formated = [];
    for (const key of keys) {
      let format = key.toUpperCase();

      for (const replace of REPLACE_REG) {
        format = format
          .replace(replace.reg, replace.val)
          .split('+')
          .map((key) => {
            if (key.match(/^a-Z/)) {
              return startCase(key);
            }
            return key;
          })
          .join(' ');
      }

      formated.push(format);
    }

    return formated;
  }

  getList() {
    return this.list;
  }

  keysToString(key: string): string {
    for (const replace of REPLACE_REG) {
      key = key.replace(replace.reg, replace.val).replace(/\+/g, ' + ');
    }

    return key;
  }

  onKey(
    keys: KeyboardEventCode[] | KeyboardEventCode,
    callback: (...args: any[]) => void,
    unrestricted?: boolean,
  ): void {
    if (isString(keys)) keys = [keys];
    for (const key of keys) {
      this.on(((unrestricted ? 'unrestrictedkey:' : 'key:') + key) as KeyCode, callback);
    }
  }

  handleKeyDown = (e: KeyboardEvent) => {
    // Don't do shortcuts inside an iframe
    if (isWithinIframe()) return;
    // prevent default on alt. For some reason windows doesn't like it
    if (e.which === 18 && !isMac) e.preventDefault();
    this.altDown = e.altKey;
    this.ctrlDown = e.ctrlKey;
    this.shiftDown = e.shiftKey;
    this.metaDown = e.metaKey;
    if (!isFocusingInput()) {
      if (e.code === 'Space') this.spaceDown = true;
      // KeyUp is not fired when the meta key, so avoid setting zDown, else you can get locked in
      if (e.code === 'KeyZ' && !this.metaDown) this.zDown = true;
    }
    this.anyKeyListeners.forEach((cb) => cb(this));

    const keyId = this.getEventId(e);
    const shortcutId = this.keymap[keyId];
    const shortcut: ShortcutModel = this.shortcuts[shortcutId];

    this.emit(('unrestrictedkey:' + keyId) as KeyCode, e, keyId);
    if (e.defaultPrevented) return;

    if (shortcut && shortcut.unrestricted) {
      if (shortcut.default) e.preventDefault();
      this.emit(shortcutId, e, shortcut);
    }
    if (!this.isRestricted(e)) {
      this.emit(('key:' + keyId) as KeyCode, e, keyId);

      if (e.defaultPrevented) return;

      if (shortcutId) {
        // const shortcut: ShortcutModel = shortcuts[shortcutId];
        if (shortcut.default) {
          e.preventDefault();
        }

        this.emit(shortcutId, e, shortcut, shortcutId);
      }
    }
  };

  isRestricted({ target }: KeyboardEvent): boolean {
    const { activeElement } = (target as Element).ownerDocument || document;

    if (!activeElement) {
      return false;
    }

    return (
      RESTRICTED_TAGS[activeElement.tagName] ||
      RESTRICTED_ROLES[activeElement.getAttribute('role') || ''] ||
      isFormElement(activeElement)
    );
  }

  private handleBlur = () => {
    this.altDown = false;
    this.ctrlDown = false;
    this.shiftDown = false;
    this.metaDown = false;
    this.spaceDown = false;
    this.zDown = false;
    this.anyKeyListeners.forEach((cb) => cb(this));
  };

  private handleKeyUp = (e: KeyboardEvent) => {
    this.altDown = e.altKey;
    this.ctrlDown = e.ctrlKey;
    this.shiftDown = e.shiftKey;
    this.metaDown = e.metaKey;
    if (e.code === 'Space') this.spaceDown = false;
    if (e.code === 'KeyZ') this.zDown = false;
    this.anyKeyListeners.forEach((cb) => cb(this));
    // fire off listeners for keyup
    const keyId = this.getEventId(e);
    if (e.defaultPrevented) return;
    const list = this.upListeners[keyId];
    if (list) list.forEach((cb) => cb(e));
  };

  onUp(event: KeyboardEventCode, callback: (...args: any[]) => void) {
    const list = this.upListeners[event] || (this.upListeners[event] = []);
    list.push(callback);
  }

  offUp(event: KeyboardEventCode, callback: (...args: any[]) => void) {
    const list = this.upListeners[event];
    if (list) {
      const index = list.indexOf(callback);
      if (index !== -1) list.splice(index, 1);
    }
  }

  on(event: KeyCode, callback: (...args: any[]) => void) {
    const list = this.listeners[event] || (this.listeners[event] = []);
    list.push(callback);
  }

  onShortcut(shortcutId: string, callback: (...args: any[]) => void) {
    const list = this.listeners[shortcutId] || (this.listeners[shortcutId] = []);
    list.push(callback);
  }

  offShortcut(shortcutId: string, callback: (...args: any[]) => void) {
    const list = this.listeners[shortcutId];
    if (list) {
      const index = list.indexOf(callback);
      if (index !== -1) list.splice(index, 1);
    }
  }

  off(event: KeyCode, callback: (...args: any[]) => void) {
    const list = this.listeners[event];
    if (list) {
      const index = list.indexOf(callback);
      if (index !== -1) list.splice(index, 1);
    }
  }

  offKey(keys: KeyboardEventCode[] | KeyboardEventCode, callback: (...args: any[]) => void) {
    if (isString(keys)) keys = [keys];
    for (const key of keys) {
      this.off(('key:' + key) as KeyCode, callback);
      this.off(('unrestrictedkey:' + key) as KeyCode, callback);
    }
  }

  /**
   * A function triggered on any key down and up, also on window blur, can be used for keeping track of modifier keys
   */
  onAny(cb: (keyManager: this) => void) {
    const index = this.anyKeyListeners.indexOf(cb);
    if (index === -1) this.anyKeyListeners.push(cb);
  }

  offAny(cb: (keyManager: this) => void) {
    const index = this.anyKeyListeners.indexOf(cb);
    if (index !== -1) this.anyKeyListeners.splice(index, 1);
  }

  emit(event: KeyCode, ...args: any[]) {
    const list = this.listeners[event];
    if (list) {
      for (const cb of list) cb.apply(this, args);
    }
  }
}

function isFormElement(element: Element): boolean {
  if (element.tagName === 'FORM') return true;
  else if (element.parentElement) return isFormElement(element.parentElement);
  return false;
}

export default new Keyboard();
