import React, { HTMLAttributes, useEffect, useState } from 'react';
import { ModelProvider } from '../core/context';
import {
  useEditorState,
  useModel,
  useRegisterHostRef,
  useVevEvent,
  useVisible,
  useWidget,
} from '../core/hooks';
import { isString } from '../utils';
import { Addons } from './addon';
import ErrorBounds from './error-bounds';

interface WidgetNodeProps {
  /** Model key */
  id: string;
  // model: IContent;
  container?: HTMLDivElement;
  children?: React.ReactNode;
  // hostRef: React.RefObject<HTMLDivElement>;
  className?: string;
  fixed?: boolean;
  section?: boolean;
}

function WidgetNode({ id, section, fixed, className }: WidgetNodeProps) {
  const [overrideVariant, setOverrideVariant] = useState<string | undefined>();
  const { disabled } = useEditorState();
  const model = useModel(id, overrideVariant);
  const Widget = useWidget(model?.type, model?.variablesApplied);
  const hostRef = React.useRef<HTMLDivElement>(null);
  const modelId = model?.virtualKey || model?.key || id;
  const currentVariant = overrideVariant || model?.variant;

  useEffect(() => {
    if (disabled && overrideVariant) setOverrideVariant(undefined);
  }, [disabled, overrideVariant]);

  useRegisterHostRef(modelId, hostRef);

  useVevEvent<{ variant: string }>(
    'CHANGE_VARIANT',
    ({ variant }) => setOverrideVariant(variant || 'default'),
    hostRef,
  );
  useVevEvent<{ variant: string }>(
    'TOGGLE_VARIANT',
    ({ variant }) => {
      setOverrideVariant((currentVariant) => (currentVariant === variant ? 'default' : variant));
    },
    hostRef,
  );

  // If not instance of master component, model?.key will be KEY(11)
  // If instance of master component, model?.key will be KEY(11)-MASTERKEY(11)
  // If nested instance of master component, model?.key will be KEY(11)-MASTERKEY(11)-MASTERKEY(11)

  if (!model || !model.type) return null;

  let cl = '__' + model.type + (className ? ' ' + className : '');
  let contentCl = model.type;
  if (model.cl) contentCl += ' ' + model.cl;

  let childrenCl = '__c';

  const contentProps: HTMLAttributes<HTMLDivElement> = {
    id: modelId + 'c',
    className: contentCl,
  };

  if (isString(model.master)) {
    cl += ` ${model.master}`;
    contentProps.className += ` ${model.master}`;
  } else if (model.master) cl += ` ${modelId}`;

  if (currentVariant && currentVariant !== 'deafult') {
    contentProps.className += ` ${currentVariant}`;
    cl += ` ${currentVariant}`;
  }

  if (model.fromModel) {
    contentProps.className += ` ${model.fromModel}`;
    cl += ` ${model.fromModel}`;
  }

  if (fixed) cl += ' __f';
  if (section) {
    cl += ' __s';
    childrenCl += ' __sc';
  } else if (model.html || (Widget && (Widget as any).static)) cl += ' __pre'; // Check if widget has added static attribute;

  if (model.html) contentProps.dangerouslySetInnerHTML = { __html: '' };

  if (model.type === 'frame' && !section) {
    contentProps.className += ' __wc __c';

    return (
      <ModelProvider value={model}>
        {React.createElement(
          'vev',
          {
            id: modelId,
            className: cl,
            ref: hostRef,
            tabIndex: model.hasFocusedState ? 0 : undefined,
          },
          <Addons addons={model.actions} hostRef={hostRef}>
            {React.createElement(
              model.attrs?.tag || 'div',
              { ...contentProps, ...(model.attrs || {}) },
              <>
                {model.children?.map((id) => (
                  <WidgetNode key={id} id={id} />
                ))}
              </>,
            )}
          </Addons>,
        )}
      </ModelProvider>
    );
  }

  const content = React.createElement(
    'w',
    contentProps,
    model.html || !Widget ? undefined : (
      <Widget section={section} {...model.content} hostRef={hostRef} />
    ),
  );

  // Need to use create element to get rid of error of not supported jsx element
  return React.createElement(
    'vev',
    {
      id: modelId,
      className: cl,
      ref: hostRef,
      key: modelId + model.actions?.join() || '',
    },
    <ErrorBounds>
      {React.createElement(
        model.attrs?.tag || 'div',
        { className: '__wc', ...(model.attrs || {}) },
        <Addons addons={model.actions} hostRef={hostRef}>
          <ModelProvider value={model}>
            {content &&
              (model.pin ? (
                <PinContentWrapper hostRef={hostRef}>{content}</PinContentWrapper>
              ) : (
                content
              ))}
            {(section || (model.children?.length || 0) > 0) && (
              <div className={childrenCl}>
                {model.children?.map((id) => (
                  <WidgetNode key={id} id={id} />
                ))}
              </div>
            )}
          </ModelProvider>
        </Addons>,
      )}
    </ErrorBounds>,
  );
}

export default WidgetNode;

function PinContentWrapper({
  hostRef,
  children,
}: {
  hostRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}) {
  const visible = useVisible(hostRef);
  return <div className={visible ? '__pc' : '__pre__pc'}>{children}</div>;
}
