import { IEmojiIcon, IDesktopProps } from '.';

export type IWidgetType = {
  standard: 0;
  section: 1;
  action: 2;
  both: 3;
};

export enum WidgetSize {
  auto = 0,
  value = 1,
  preferAuto = 2,
  preferValue = 3,
}

export type WidgetDimensions = [width: WidgetSize, height: WidgetSize];

export type IWidgetRule = {
  key: string;
  name?: string;
  icon?: IEmojiIcon;
  props: IDesktopProps;
  inherits?: IWidgetRule;
  isMainBg?: boolean;
};

export type IWidgetMetaData = {
  selector: string;
  name?: string;
  icon?: IEmojiIcon;
  inherits?: IWidgetMetaData;
  isMainBg?: boolean;
};

export type WidgetModel = {
  image?: IEmojiIcon | string;
  key: string;
  name: string;
  page: string;
  pageName?: string;
};
