import { isFunction } from './util';

function isNumber(char: string) {
  if (!char) return false;
  const charCode = char.charCodeAt(0);
  return charCode >= 48 && charCode <= 57;
}

function isChar(char: string): boolean {
  if (!char) return false;
  const charCode = char.charCodeAt(0);
  return (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122);
}

function isHyphen(char: string) {
  return char === '-';
}

function isOp(char: string): boolean {
  return ['+', '-', '*', '/', '^'].includes(char);
}

function isParens(char: string): char is '(' | ')' {
  return char === '(' || char === ')';
}

export enum TokenType {
  NUMBER,
  OPERATOR,
  PARENS,
  FUNCTION,
}

type NumberToken = {
  type: TokenType.NUMBER;
  value: number;
};

type OperatorToken = {
  type: TokenType.OPERATOR;
  value: string;
};

type ParensToken = {
  type: TokenType.PARENS;
  value: string;
};

type FunctionToken = {
  type: TokenType.FUNCTION;
  value: string;
};

type Token = NumberToken | OperatorToken | ParensToken | FunctionToken;

export class TokenIterator {
  private expression = '';
  private currentIndex = 0;
  private variableScope: Record<string, number> = {};

  init(expression: string, variableScope: Record<string, number>) {
    this.expression = expression;
    this.currentIndex = 0;
    this.variableScope = variableScope;
  }

  curr() {
    return this.expression[this.currentIndex];
  }

  peekNext() {
    return this.expression[this.currentIndex + 1];
  }

  next(): string {
    this.currentIndex += 1;
    return this.expression[this.currentIndex - 1];
  }

  // Required for iterator
  *[Symbol.iterator](): Generator<Token> {
    while (!this.done()) {
      if (this.curr() === ' ') this.next();
      else if (isNumber(this.curr()) || (this.curr() === '-' && isNumber(this.peekNext())))
        yield { type: TokenType.NUMBER, value: this.handleNumber() };
      else if (isOp(this.curr())) yield { type: TokenType.OPERATOR, value: this.next() };
      else if (isChar(this.curr())) yield this.handleString();
      else if (isParens(this.curr())) yield { type: TokenType.PARENS, value: this.next() };
      else {
        throw Error(`Unknown symbol ${this.curr()}`);
      }
    }
  }

  done() {
    return this.currentIndex === this.expression.length;
  }

  handleNumber(): number {
    let result = '';
    while (isNumber(this.curr()) || this.curr() === '.' || this.curr() === '-') {
      result += this.next();
    }
    return parseFloat(result);
  }

  handleString(): Token {
    let result = '';
    while (isChar(this.curr()) || isNumber(this.curr()) || isHyphen(this.curr())) {
      result += this.next();
    }

    if (isFunction(result)) {
      return { type: TokenType.FUNCTION, value: result };
    }

    if (this.variableScope[result] === undefined) throw new Error(`No variable ${result}`);
    return { type: TokenType.NUMBER, value: this.variableScope[result] };
  }
}
