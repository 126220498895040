import { compile, COMMENT, RULESET, Element, IMPORT, DECLARATION } from 'stylis';
import { ComputedCSSProps, ComputedCSS, CSS_VEV_REG, CSS_VEV_VAR_REG } from './utils';

export const CSS = {
  parse(cssString: string): ComputedCSS {
    const children = compile(cssString);
    const res: ComputedCSS = {};
    for (const element of children) {
      selectorObject(element, res);
    }
    return res;
  },
};

function replaceVevVariables(styleValue: string): string {
  return styleValue.trim().replace(CSS_VEV_REG, '$1').replace(CSS_VEV_VAR_REG, `var(--$1)`);
}

function prefixFontFamily(attr: string, value: string) {
  return attr === 'font-family' &&
    value &&
    !value.startsWith('"') &&
    !value.startsWith('var(--vev-')
    ? `"${value}"`
    : value;
}

function selectorObject(element: Element, res: ComputedCSS | ComputedCSSProps): void {
  switch (element.type) {
    case IMPORT:
      break;
    case DECLARATION:
      (res as ComputedCSSProps)[element.props as string] = prefixFontFamily(
        element.props as string,
        replaceVevVariables(element.children as string),
      );
      return;
    case COMMENT:
      return;
    case RULESET:
      element.value = (element.props as string[]).join(',');
  }
  const children = element.children as Element[];
  if (children.length) {
    res[element.value] = res[element.value] || {};
    for (const child of children as Element[]) {
      selectorObject(child, res[element.value as string] as ComputedCSSProps);
    }
  }
}

export const getComputedCSS = (css: string) => CSS.parse(css);
