import { IEmojiIcon, IShape, IVevImage } from 'vev';
import { isString } from '../../utils';
import { useContextModel } from '../context';
import { useGlobalStore } from './use-store';

/**
 * `useIcon` watches and returns the given icon,
 *  @param{iconKey} string - is the key of the icon you wish to get, icon keys can be found in the widgets manifest
 */
export function useIcon(
  iconKey: string | IShape | IEmojiIcon | IVevImage,
): IShape | string | undefined | IEmojiIcon | IVevImage {
  const model = useContextModel();
  const shapes = useGlobalStore((state) => state.shapes);
  if (!isString(iconKey)) return iconKey;
  if (model?.icons) return model.icons[iconKey];
  if (shapes?.[iconKey]) return shapes[iconKey];

  return [
    128,
    128,
    'M103 38.8L84 89.2h-8.6l-19-50.5h8l12.2 32.1 2.8 10.9h.5l2.8-10.9 12.2-32.1 8.1.1zM44 68.4H28v20.8h16.4V85H32.9v-4.5h10.3v-4.2H32.9v-3.5H44v-4.4zm-7.9-12.7l-1.2-4.5-4.4-12.5H25l7.5 20.8h7.3l7.5-20.8h-5.5l-4.4 12.5-1.2 4.5h-.1z',
  ] as IShape;
}
