/**
 * Create search regular express which matches partial words in random order
 */

export function escapeRegExp(value: string) {
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function getSearchReg(search: string): RegExp {
  const terms = escapeRegExp(search)
    .split(/\s+/)
    .map((term) => `(?=.*${term})`)
    .join('');

  try {
    return new RegExp(terms, 'i');
  } catch (e) {
    console.warn('Error parsing creating regex from "' + search + '"');

    // Return fallback regex
    return /.*/i;
  }
}
