import React from 'react';

type Props = {
  text: string;
  tag: string;
  html?: boolean;
};

export const VariableText = (props: Props) => {
  const value = props?.text;

  if (props?.html) {
    return React.createElement('div', {
      dangerouslySetInnerHTML: { __html: value },
    });
  }

  return React.createElement(props.tag, null, value);
};
