import React from 'react';
import { useModel } from '../core/hooks';
import WidgetNode from './widget-node';
import { VevContent } from 'vev';

function Fixed({ pageKey }: { pageKey: string }) {
  const fixed: VevContent['fixed'] = useModel('fixed') || ({} as any);
  if (!pageKey || !fixed) return null;
  const { children = [], visibility = {} } = fixed;

  return (
    <div className="fixed">
      {children
        .filter((id) => {
          const modelVisibility = visibility[id] || true;
          if (typeof modelVisibility === 'object') return modelVisibility[pageKey] === true;
          return modelVisibility === true;
        })
        .map((id) => (
          <WidgetNode key={id} id={id} fixed />
        ))}
    </div>
  );
}

export default React.memo(Fixed);
