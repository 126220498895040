import { useGlobalStore } from './use-store';

/**
 * `useScrollTop` watches and returns the current scroll top
 * if percentage argument is true the scroll progress is return as float between [0,1]
 */
export function useScrollTop(percentage?: boolean): number {
  return useGlobalStore(
    ({ scrollTop, viewport }) =>
      percentage
        ? viewport.scrollHeight <= viewport.height
          ? 1
          : Math.max(0, scrollTop / (viewport.scrollHeight - viewport.height))
        : scrollTop,
    [percentage],
  );
}
