import { IPage } from 'vev';
import { useGlobalStore } from './use-store';

export function usePages(): [IPage[], string | undefined] {
  return useGlobalStore((s) => [s.pages, s.dir]);
}

export function usePage(pageKey?: string): IPage | undefined {
  return useGlobalStore((s) => s.pages).find((page) => page.key === pageKey);
}

/**
 * `useIsIndexPage` returns true if current page is index page
 */
export function useIsIndexPage(): boolean {
  return useGlobalStore((state) => {
    const currentPageKey = state?.route?.pageKey;
    const currentPage = state.pages.find((page) => page.key === currentPageKey);
    return currentPage?.index === true;
  });
}
