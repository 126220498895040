import React, { DependencyList, useEffect } from 'react';
import { findParent, root } from '../../utils';

/**
 * `useLiveEvent` can be used to watch all events on a give tag of a certain type happening inside a given element
 * @example useLiveEvent('a', 'click', document.body, () => console.log('LINK CLICKED'));
 */
export function useLiveEvent<K extends keyof HTMLElementEventMap>(
  tagname: string,
  eventName: K,
  rootEl: React.RefObject<HTMLElement | undefined> | HTMLElement = root,
  callback: (ev: HTMLElementEventMap[K], source: HTMLElement) => any,
  deps?: DependencyList,
) {
  useEffect(() => {
    const el = rootEl instanceof Element ? rootEl : rootEl.current;

    const handler = (e: HTMLElementEventMap[K]) => {
      const target = findParent(tagname.toUpperCase(), (e.target || e.srcElement) as HTMLElement);
      if (target) callback(e, target);
    };

    if (el) {
      el.addEventListener(eventName, handler);
    }
    return () => {
      if (el) {
        el.removeEventListener(eventName, handler);
      }
    };
  }, deps || []);
}
