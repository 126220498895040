import { IMenu } from 'vev';
import { useGlobalStore } from './use-store';

const emptyMenu = { children: [], title: '' };

/**
 * `useMenu` watches and returns the given menu
 * if no menu key defined the primary menu will be used
 */
export function useMenu(menuKey?: string): IMenu {
  return useGlobalStore(
    ({ menus, primaryMenu }) => {
      const key = menuKey || primaryMenu;
      return (menus && key && menus[key]) || emptyMenu;
    },
    [menuKey],
  );
}
