import React, { useEffect } from 'react';
import { VevContent } from 'vev';
import { useGlobalStateRef, useModel, usePage, useTracking } from '../core/hooks';
import { useScrollSnap } from '../core/hooks/use-scroll-snap';
import { loadPage } from '../utils/route';
import WidgetNode from './widget-node';
import { StandardEventType } from '@vev/interactions';

interface PageProps {
  id: string;
  className?: string;
  active?: boolean;
}

function useHeaderFooterSections(
  pageKey: string,
  list?: string[],
  visibility?: { [modelKey: string]: boolean | string | { [pageKey: string]: string | boolean } },
  placement: 'header' | 'footer' = 'header',
): string[] {
  if (!list || !visibility) return [];

  return list.filter((key) => {
    const modelVisibility = visibility[key] || {};
    if (typeof modelVisibility === 'string' && modelVisibility === placement) return true;
    if (typeof modelVisibility === 'object' && modelVisibility[pageKey] === placement) return true;
    return false;
  });
}

function Page({ id, className, active, ...rest }: PageProps, ref: React.Ref<HTMLDivElement>) {
  const model = useModel(id);
  const page = usePage(id);
  const track = useTracking();

  const project: VevContent['fixed'] = useModel('fixed') || ({} as any);
  const header = useHeaderFooterSections(id, project.children, project.visibility, 'header');
  const footer = useHeaderFooterSections(id, project.children, project.visibility, 'footer');
  const [state, dispatch] = useGlobalStateRef();

  const isSnap = page?.scrollSnap;
  useScrollSnap(!!isSnap);

  useEffect(() => {
    if (!model) loadPage(id, state.current, dispatch);
  }, [model, id, state, dispatch, track]);

  useEffect(() => {
    track(StandardEventType.pageLoad, {
      pageKey: id,
      pageName: state.current.pages.find((p) => p.key === id)?.title,
      projectKey: state.current.project,
      breakpoint: state.current.device,
    });
  }, []);

  return (
    <div className={'__p' + (className ? ' ' + className : '')} id={id} ref={ref}>
      {header.length > 0 && (
        <header>
          {header?.map((key, i) => (
            <WidgetNode key={i} id={key} section className={isSnap ? '__snap' : ''} />
          ))}
        </header>
      )}
      <main>
        {model?.children?.map((key, i) => (
          <WidgetNode key={i} id={key} section className={isSnap ? '__snap' : ''} />
        ))}
      </main>
      {footer.length > 0 && (
        <footer>
          {footer.map((key, i) => (
            <WidgetNode key={i} id={key} section className={isSnap ? '__snap' : ''} />
          ))}
        </footer>
      )}
    </div>
  );
}

export default React.memo(React.forwardRef(Page));
