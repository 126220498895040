import React from 'react';
import { System } from '@vev/react';

let currenctSource: EventSource;

export function connectCLI() {
  console.log('📡 Trying to connect Vev CLI');
  const devServerURL = 'http://localhost:9876';
  const fetchFromDevServer = async (url: string): Promise<string> => {
    const req = await fetch(devServerURL + url);
    return req.text();
  };
  let cssElement: HTMLStyleElement | null = null;
  const addCSS = (css: string) => {
    if (!cssElement) {
      cssElement = document.createElement('style');
      document.head.appendChild(cssElement);
    }
    cssElement.innerHTML = css;
  };

  const addJS = (js: string) => {
    const match = js.match(/System\.register\("([^"]*)"/);
    if (match) {
      System.delete(match[1]);
      // @ts-ignore
      eval(`vevr.push((vev,System,React) =>{
        ${js}
      })`);
    }
  };

  const loadLocalWidgets = async () => {
    try {
      await Promise.all([
        fetchFromDevServer('/develop/bundle.css').then(addCSS),
        fetchFromDevServer('/develop/bundle.js').then(addJS),
      ]);
      console.log('🤗 Hot reload from Vev CLI done');
    } catch (e) {
      console.error('🛑 Failed to fetch from local cli');
      console.error(e);
    }
  };

  const listen = async () => {
    const events = new EventSource(devServerURL + '/events');

    events.onmessage = async (e: MessageEvent) => {
      console.log('got update', e.data);
      loadLocalWidgets();
    };

    events.onopen = (e) => {
      console.log('🟢 Vev CLI connected');
    };
  };

  listen();
}
