import { useEffect, useRef, useState } from 'react';

type ResizeCallback = (size: { width: number; height: number }) => void;
const OBSERVING = new Map<Element, Set<ResizeCallback>>();
// It's more performance to use 1 resize observer according to WICG https://github.com/WICG/resize-observer/issues/59#issuecomment-408098151
let observer: ResizeObserver;

function watchElementSize(el: Element, cb: ResizeCallback) {
  if (!observer) {
    observer = new ResizeObserver((entries) => {
      for (const { target, contentRect } of entries) {
        const size = { width: contentRect.width, height: contentRect.height };
        const cbs = OBSERVING.get(target);
        if (cbs) {
          for (const cb of cbs) cb(size);
        }
      }
    });
  }
  // If no watchers then start observing
  if (!OBSERVING.has(el)) {
    OBSERVING.set(el, new Set());
    observer.observe(el);
  }
  const listeners = OBSERVING.get(el) as Set<ResizeCallback>;
  listeners.add(cb);

  return () => {
    listeners.delete(cb);
    if (!listeners.size) {
      OBSERVING.delete(el);
      observer.unobserve(el);
    }
  };
}

/**
 * `useSize` watches and returns the size of a html ref element,
 */
export function useSize<T extends Element>(
  ref: React.RefObject<T>,
): { width: number; height: number };
export function useSize<T extends Element>(
  ref: React.RefObject<T>,
  onChange: (size: { width: number; height: number }) => void,
): void;
export function useSize<T extends Element>(
  ref: React.RefObject<T>,
  onChange?: (size: { width: number; height: number }) => void,
): { width: number; height: number } | void {
  const [size, setSize] = useState<{ width: number; height: number }>(() => {
    const el = ref.current;
    return { width: el ? el.clientWidth : 0, height: el ? el.clientHeight : 0 };
  });
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  useEffect(() => {
    const el = ref.current;
    if (el) {
      return watchElementSize(el, (size) => {
        const onChange = onChangeRef.current;
        if (onChange) onChange?.(size);
        else setSize(size);
      });
    }
  }, [ref.current]);

  if (onChange) return;
  return size;
}
