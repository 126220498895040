import { Variable } from './types';
import { evaluateExpression } from './set-variable-math/evaluate-expression';

declare global {
  interface Window {
    vevVariableDebug: boolean;
  }
}

export class VariableManager {
  initialVars: Record<string, Variable> = {};
  // This should be a singleton object so it can be used anywhere
  readonly values: Record<string, any> = {};

  constructor(public rootNode: HTMLElement, valueSingleton: Record<string, any> = {}) {
    this.values = valueSingleton;
    this.log('Initialized VariableManager');

    rootNode.addEventListener<any>('vev.variable.set', this.handleEventSet);
  }

  destroy() {
    this.rootNode.removeEventListener<any>('vev.variable.set', this.handleEventSet);
  }

  reset() {
    // Remove all old values
    for (const key in this.values) delete this.values[key];
    // Set initial values
    for (const key in this.initialVars) {
      this.values[key] = this.initialVars[key].value;
    }
  }

  setInitialVars(variables: Variable[]) {
    this.initialVars = variables.reduce((acc, v) => {
      acc[v.key] = v;
      return acc;
    }, {} as { [key: string]: Variable });
    this.reset();
  }

  set(key: string, value: any, overrideUnit?: string) {
    const variable = this.initialVars[key];
    if (!variable) return;

    value = value === undefined ? variable.value : evaluateExpression(value, this.values);
    const unit = overrideUnit || variable.unit;

    this.log(`✏️ set variable ${key}:${JSON.stringify(`${value}${unit ? unit : ''}`)}`);

    this.values[key] = value;

    if (variable.type !== 'text') {
      this.rootNode.style.setProperty(
        `--vev-${key}`,
        value !== undefined || value !== null ? `${value}${unit ? unit : ''}` : '',
      );
    }

    this.emit({
      ...variable,
      value,
    });
  }

  get(key: string) {
    return this.values[key];
  }

  private handleEventSet = (e: CustomEvent) => {
    const { key, value, unit } = e.detail;
    this.set(key, value, unit);
  };

  private emit(value: Variable) {
    this.rootNode.dispatchEvent(
      new CustomEvent('vev.variable.change', {
        detail: value,
      }),
    );
  }

  private log(...data: any[]) {
    if (window.vevVariableDebug) {
      console.log('🟢 VariableManager | ', ...data);
    }
  }
}
