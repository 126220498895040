import { useGlobalStateRef } from './use-global-state';
import {
  dispatchTrackingEvent,
  StandardEventType,
  EventData,
  EventMetaData,
} from '@vev/interactions';

export function useTracking(disabled = false) {
  const [state] = useGlobalStateRef();

  return (type: StandardEventType | string, data: EventData | any = {}) => {
    if (!type || disabled) return;

    const metaData: EventMetaData = {
      projectKey: state.current.project || 'no-project',
      pageKey: state.current.route.pageKey,
      timestamp: Date.now(),
    };

    dispatchTrackingEvent(type, data, metaData);
  };
}
