import { useEffect } from 'react';
import { useEditorState } from './use-editor-state';

const scrollSnap = (scrollEl: Element | null, active: boolean) => {
  if (scrollEl) {
    const { body, documentElement } = scrollEl.ownerDocument;

    if (active) {
      body.style['scroll-snap-type' as any] = 'y mandatory';
      body.style['scroll-behavior' as any] = 'smooth';
      // Add dirty hack to prevent infinite top scroll
      body.style.height = '100%';
      body.style.overflow = 'auto';
      documentElement.style.height = '100%';
      documentElement.style.overflow = 'clip';
    } else {
      body.style['scroll-snap-type' as any] = 'unset';
      body.style['scroll-behavior' as any] = 'unset';
      // Remove dirty hack
      body.style.height = 'unset';
      body.style.overflow = 'unset';
      documentElement.style.height = 'unset';
      documentElement.style.overflow = 'unset';
    }
  }
};

export function useScrollSnap(snap: boolean) {
  const { disabled } = useEditorState();

  useEffect(() => {
    if (disabled) return scrollSnap(document.scrollingElement, false);
    scrollSnap(document.scrollingElement, snap);
    () => scrollSnap(document.scrollingElement, false);
  }, [snap, disabled]);
}
