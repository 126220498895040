import React, { createContext, useContext, useMemo } from 'react';
import { IContent } from 'vev';
import { useModel } from '../hooks';

export const VariantContext = createContext<{ [key: string]: Partial<IContent> } | null>(null);

export function VariantOverrideProvider({
  variantKey,
  children,
}: {
  variantKey: string | undefined;
  children: React.ReactNode;
}) {
  const parentOverrides = useContext(VariantContext);
  const variant = useModel(variantKey || '');

  const overrides = useMemo(() => {
    const map = { ...(parentOverrides || {}), ...(variant?.overrides || {}) };
    for (const key in map) {
      if ((parentOverrides || {})[key]) map[key] = { ...parentOverrides?.[key], ...map[key] };
    }
    return map;
  }, [parentOverrides, variant]);

  return <VariantContext.Provider value={overrides}>{children}</VariantContext.Provider>;
}

export function useVariantOverrides(key: string): Partial<IContent> | undefined {
  const overrides = useContext(VariantContext);
  return overrides?.[key];
}
