import { useEffect } from 'react';
import { useCallbackRef } from './use-callback-ref';

/**
 * `useInterval` simple way of starting a set interval in component
 * @param callback - function to be called on interval
 * @param delay - interval delay in ms or null|false|undefined to stop the interval
 */
export function useInterval(callback: () => any, delay: number | false | null | undefined) {
  const cbRef = useCallbackRef(callback);

  // Set up the interval.
  useEffect(() => {
    if (typeof delay === 'number') {
      const id = setInterval(() => cbRef.current(), delay);
      return () => clearInterval(id);
    }
  }, [delay, cbRef]);
}
