export type ComputedCSSProps = { [cssPropAttr: string]: string | null };

export type ComputedCSS = {
  [selector: string]: ComputedCSSProps;
};

export const SPACE_REG = /\s+/g;
/** Regexp used to clean up unnecessary spaces */
export const COMMA_SPACE_REG = /\s*,\s*/g;
/** Regexp to find editable css values */
export const CSS_VEV_REG = /^vev\((.*)\)\s*;?$/;
/** Regexp to find vev variables */
export const CSS_VEV_VAR_REG = /\$(primary?\S|accent?\S|bg?\S)/g;

export function getCSSAttrParts(attr: string, delimiter = ',') {
  const parts: string[] = [];
  let nestCount = 0;
  let prevPart = 0;
  for (let i = 0; i < attr.length; i++) {
    const char = attr[i];
    if (char === '(') nestCount++;
    else if (char === ')') nestCount--;
    else if (nestCount <= 0 && char === delimiter) {
      const value = attr.substring(prevPart, i).trim();
      if (value) parts.push(value);
      prevPart = i + 1;
    }
  }

  parts.push(attr.substring(prevPart, attr.length).trim());
  return parts;
}
