import { isString } from '../utils';

type MouseListener = {
  (e: MouseEvent): any;
  __priority?: number;
};

type MouseListenerMap = {
  mousemove: MouseListener[];
  mouseup: MouseListener[];
  mousedown: MouseListener[];
  mouseleave: MouseListener[];
};

type DownRect = [number, number, number, number];

export default class MouseManager {
  pageX = 0;
  pageY = 0;
  pageDownX = -1;
  pageDownY = -1;
  pageUpX = -1;
  pageUpY = -1;
  moveX = -1;
  moveY = -1;
  timestampDown = 0;
  timestampUp = 0;
  downRect: DownRect = [0, 0, 0, 0];

  /** Used in editor to store the canvas position when mouse is down */
  downCanvasPos: number[] = [0, 0];

  button = -1;

  shiftKey = false;
  altKey = false;
  metaKey = false;

  private listeners: MouseListenerMap = {
    mousemove: [],
    mouseup: [],
    mousedown: [],
    mouseleave: [],
  };

  constructor() {
    if (typeof document !== 'undefined') {
      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mousedown', this.handleMouseDown);
      document.addEventListener('mouseup', this.handleMouseUp);
      document.addEventListener('mouseleave', this.handleMouseLeave);
    }
  }

  on(event: keyof MouseListenerMap, callback: MouseListener, priority?: number) {
    const listeners = this.listeners[event];
    callback.__priority = priority || 0;
    if (listeners.indexOf(callback) === -1) {
      listeners.push(callback);
    }

    listeners.sort((f1, f2) => (f2.__priority || 0) - (f1.__priority || 0));
  }

  off(event: keyof MouseListenerMap, callback: MouseListener) {
    const listeners = this.listeners[event];
    if (!callback) {
      listeners.length = 0;
    } else {
      const index = listeners.indexOf(callback);
      if (index !== -1) {
        listeners.splice(index, 1);
      }
    }
  }

  emit = (event: keyof MouseListenerMap, e: MouseEvent) => {
    const listeners = this.listeners[event];
    for (let i = 0; i < listeners.length; i++) {
      if (listeners[i](e) === false) return;
    }
  };

  private handleMouseLeave = (e: MouseEvent) => {
    this.emit('mouseleave', e);
    if (this.isMouseDown()) {
      this.handleMouseUp(e);
    }
  };

  handleMouseMove = (e: MouseEvent) => {
    this.moveX = e.pageX - this.pageX;
    this.moveY = e.pageY - this.pageY;
    this.pageX = e.pageX;
    this.pageY = e.pageY;
    this.altKey = e.altKey;
    this.metaKey = e.metaKey;
    this.shiftKey = e.shiftKey;
    this.emit('mousemove', e);
  };

  handleMouseDown = (e: MouseEvent) => {
    this.timestampDown = e.timeStamp;
    this.button = e.button;

    this.pageDownX = e.pageX;
    this.pageX = e.pageX;

    this.pageDownY = e.pageY;
    this.pageY = e.pageY;

    this.emit('mousedown', e);
  };

  handleMouseUp = (e: MouseEvent) => {
    this.timestampUp = e.timeStamp;

    this.pageUpX = e.pageX;
    this.pageX = e.pageX;

    this.pageUpY = e.pageY;
    this.pageY = e.pageY;
    this.emit('mouseup', e);
  };

  isMouseDown() {
    return this.timestampDown > this.timestampUp;
  }

  isMouseCenterDown() {
    return this.isMouseDown() && this.button === 1;
  }

  isMouseLeftDown() {
    return this.isMouseDown() && this.button === 0;
  }

  isMouseRightDown() {
    return this.isMouseDown() && this.button === 2;
  }

  isInside(e: React.MouseEvent | MouseEvent, element: HTMLElement) {
    let parent: HTMLElement | null = e.target as HTMLElement;
    while (parent) {
      if (parent === element) return true;

      parent = parent.parentElement;
    }

    return false;
  }

  isInsideRole(e: React.MouseEvent | MouseEvent, role: RegExp | string) {
    let parent: HTMLElement | null = e.target as HTMLElement;
    if (isString(role)) role = new RegExp('^' + role + '$', 'i');
    while (parent) {
      const roleAttr = parent.getAttribute && parent.getAttribute('role');
      if (roleAttr && role.test(roleAttr)) return true;

      parent = parent.parentElement;
    }

    return false;
  }

  getDownRect(quadraticSquare: boolean, scale = 1): DownRect {
    const { pageX, pageY, pageDownX, pageDownY } = this;
    const rect = this.downRect;

    rect[0] = Math.min(pageX, pageDownX);
    rect[1] = Math.min(pageY, pageDownY);
    rect[2] = (Math.max(pageX, pageDownX) - rect[0]) * scale;
    rect[3] = (Math.max(pageY, pageDownY) - rect[1]) * scale;
    if (quadraticSquare) {
      const newDim = Math.max(rect[2], rect[3]);
      if (pageX < pageDownX) rect[0] += rect[2] - newDim;
      if (pageY < pageDownY) rect[1] += rect[3] - newDim;

      rect[2] = newDim;
      rect[3] = newDim;
    }
    return rect;
  }

  mouseDownFor(moreThanMs: number) {
    return this.timestampUp - this.timestampDown > moreThanMs;
  }

  getDownDist() {
    return Math.sqrt(this.getDownDistX() ** 2 + this.getDownDistY() ** 2);
  }

  getDownDistX() {
    return this.pageX - this.pageDownX;
  }

  getDownDistY() {
    return this.pageY - this.pageDownY;
  }
}
