import { useEffect, useState } from 'react';

export type VisibleOptions = {
  /**
   * offset the viewport intersection trigger from top of the viewport
   * i.e offsetTop = 50%, then the offset is at the center of the screen,
   * so when bottom of element reaches center of screen the el is not intersecting anymore and intersectionRatio = 0
   * */
  offsetTop?: number | string;
  /**
   * offset the viewport intersection trigger from bottom of the viewport
   * i.e offsetBottom = 50%, then the offset is at the center of the screen,
   * so when top of element reaches center of screen then the intersection starts
   * */
  offsetBottom?: number | string;
};

export type ObserverOptions = VisibleOptions & {
  /** Number of steps the intersection takes, defaults to 1 */
  steps?: number;
  /**  Threshold to trigger on, not possible to define both step and threshold */
  threshold?: number[];
};
type ObserverCallback = (entry: IntersectionObserverEntry) => any;

type ObserveItem = {
  observer: IntersectionObserver;
  listeners: Map<Element, Set<ObserverCallback>>;
};

const observers = new Map<string, ObserveItem>();

function getId(options?: ObserverOptions) {
  if (!options) return '0';
  return `${options.offsetTop}-${options.offsetBottom}-${options.threshold || options.steps || 0}`;
}

function observeElement(el: Element, cb: ObserverCallback, options?: ObserverOptions) {
  const id = getId(options);
  if (!observers.has(id)) {
    observers.set(id, {
      observer: new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const { target } = entry;
          const listeners = observers.get(id)?.listeners.get(target);
          if (listeners) listeners.forEach((cb) => cb(entry));
        });
      }, getObserveOptions(options)),
      listeners: new Map(),
    });
  }

  const { observer, listeners } = observers.get(id) as ObserveItem;

  if (!listeners.has(el)) {
    listeners.set(el, new Set());
    observer.observe(el);
  }

  const cbs = listeners.get(el) as Set<ObserverCallback>;
  cbs.add(cb);

  return () => {
    cbs.delete(cb);
    if (!cbs.size) {
      listeners.delete(el);
      observer.unobserve(el);
    }
  };
}

const formatOffset = (value?: number | string): string => {
  if (typeof value === 'number') return value * -1 + 'px';
  if (value) return value.charAt(0) === '-' ? value.substring(1) : '-' + value;
  return '0px';
};

function getObserveOptions(options?: ObserverOptions): IntersectionObserverInit | undefined {
  if (!options) return undefined;
  // Init threshold
  if (typeof options === 'number') options = { steps: options };
  const { offsetTop, offsetBottom, steps, threshold } = options;

  const observerOpts: IntersectionObserverInit = {};
  if (offsetTop || offsetBottom) {
    observerOpts.rootMargin = `${formatOffset(offsetTop)} 0px ${formatOffset(offsetBottom)} 0px`;
    observerOpts.root = null;
  }

  if (steps) {
    observerOpts.threshold = [];
    for (let i = 0; i <= steps; i++) observerOpts.threshold.push(i / steps);
  } else if (threshold) observerOpts.threshold = threshold;
}

/**
 * `useIntersection` advanced alternative for useVisible to detect the intersection % of a html ref element
 * Possible to pass options to offset the viewport size and how many intersection steps it should do
 * Pass false or undefined as ref to disable
 */
export function useIntersection<T extends Element>(
  ref: false | undefined | React.RefObject<T>,
  options?: ObserverOptions,
): false | IntersectionObserverEntry {
  const [intersection, setIntersection] = useState<IntersectionObserverEntry | false>(false);
  useEffect(() => {
    if (!ref) return;
    const el = ref.current;
    if (el) return observeElement(el, setIntersection, options);
  }, [ref, JSON.stringify(options)]);

  return intersection;
}

/**
 * `useVisible` detects when the html ref element is in viewport
 * Possible to pass options to offset the viewport size
 */
export function useVisible<T extends Element>(
  ref: false | undefined | React.RefObject<T>,
  options?: VisibleOptions,
): boolean {
  const intersection = useIntersection(ref, options);
  return intersection && intersection.isIntersecting;
}
