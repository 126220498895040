export function initShadowDomHashLinks(node: HTMLElement) {
  const handleHash = (behavior: 'auto' | 'instant') => {
    const { hash } = window.location;

    const el = node.querySelector(hash);
    if (el) {
      const tallerThanView = el.clientHeight >= window.innerHeight * 0.8;
      el.scrollIntoView({
        behavior: behavior as any,
        inline: tallerThanView ? 'start' : 'center',
      });
    }
  };
  window.addEventListener('hashchange', () => handleHash('auto'));
  if (window.location.hash) {
    handleHash('instant');
    // trying to ensure we actually scroll to the elment
    setTimeout(() => handleHash('instant'), 200);
  }
}
