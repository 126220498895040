import { VevProps } from './vev-props';
import { OAuthConfig } from './oauth';

// KV types
type KeyPart = string | number | bigint | boolean | symbol;

export type EditorPluginKvKey = KeyPart[];

interface EditorPluginKvCommitResult {
  ok: true;
  versionstamp: string;
}

interface EditorPluginKvEntry<T> {
  key: EditorPluginKvKey;
  value: T;
  versionstamp: string;
}

export interface EditorPluginKv {
  set: (key: EditorPluginKvKey, value: unknown) => Promise<EditorPluginKvCommitResult>;
  get: <T>(key: EditorPluginKvKey) => Promise<EditorPluginKvEntry<T>>;
  delete: (key: EditorPluginKvKey) => Promise<void>;
}

// Vev plugin types
export interface EditorPluginSettings {
  form: VevProps[];
}

type ImageSizes = Record<number, string>;
type MetaData = Record<string, string>;

export interface BaseProjectAsset {
  key: string;
  mimeType: string; // https://www.iana.org/assignments/media-types/media-types.xhtml
  updated: number;
  url: string;
  filename?: string;
  metaData?: MetaData;
  selfHosted?: boolean;
}

export interface ProjectImageAsset extends BaseProjectAsset {
  dimension?: { width: number; height: number };
  thumb?: string;
  imageSizes?: ImageSizes;
}

export interface ProjectVideoAsset extends BaseProjectAsset {
  videoThumbnail?: string;
  videoSample?: string;
  dimension?: { width: number; height: number };
  duration?: number;
  state?: 'uploading' | 'encoding' | 'completed' | 'error' | 'preUpload';
  additionalSources: Omit<ProjectVideoAsset, 'additionalSources'>[];
}

export type ProjectAsset = BaseProjectAsset | ProjectImageAsset | ProjectVideoAsset;

export enum EditorPluginType {
  ASSET_SOURCE = 'asset-source',
}

export interface EditorPlugin<T> {
  id: string;
  name: string;
  type: EditorPluginType;
  form: VevProps[];
  handler: (
    request: Request,
    env: Record<string, string>,
    kv: EditorPluginKv,
  ) => Promise<T | EditorPluginSettings>;
  icon?: string;
  oauthConfig?: OAuthConfig;
}

export type EditorPluginAssetSourceFilterField = {
  label: string;
  value: string;
  fields: { label: string; value: string }[];
};
export type EditorPluginAssetSourceFilterFields = EditorPluginAssetSourceFilterField[];
export interface EditorPluginAssetSource
  extends EditorPlugin<ProjectAsset[] | EditorPluginAssetSourceFilterFields> {
  type: EditorPluginType.ASSET_SOURCE;
}

export type VevPluginType = EditorPluginAssetSource;
