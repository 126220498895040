export function getCSSNumberFormat(
  value: string,
  allowNoFormat?: boolean,
): '%' | 'px' | 'vh' | 'vw' | 'em' | 'rem' | 'cm' | 'deg' | 'rad' | 's' | 'ms' | '' {
  if (!value) return 'px';
  const lastChar = value[value.length - 1];
  switch (lastChar) {
    case 'x':
      return 'px';
    case '%':
      return '%';
    case 'h':
      return 'vh';
    case 'w':
      return 'vw';
    case 'g':
      return 'deg';
    case 'd':
      return 'rad';
    case 'm':
      if (value.endsWith('rem')) return 'rem';
      if (value.endsWith('cm')) return 'cm';
      return 'em';
    case 's':
      if (value.endsWith('ms')) return 'ms';

      return 's';
  }

  if (allowNoFormat) {
    return '';
  }

  return 'px';
}
