export const openQueryParamsLink = (
  path: string,
  { target, rel }: { target?: string | null; rel?: string | null } = {},
  isEmbed?: boolean,
): Window | null => {
  // Disable if in viewer
  if (!location.host && !isEmbed) return null;

  let url;

  let params = window.location.search;
  if (path.includes('?')) {
    params = params.replace('?', '&');
  }
  if (path.includes('#')) {
    const [urlPath, hash] = path.split('#');
    url = urlPath + params + '#' + hash;
  } else {
    url = path + params;
  }

  const windowFeatures = (rel || '')
    .split(' ')
    .filter((feature) => !['external', 'sponsored', 'nofollow'].includes(feature))
    .join(',');

  return window.open(url, target || '_self', windowFeatures);
};
